import React from "react";
import { Link } from "react-router-dom";

const MenuItems = (props) => {
  const { parentMenu, activeMenu } = props;

  return (
    <React.Fragment>
      <li className={parentMenu === "home" ? "current-menu-item" : ""}>
        <Link to="" as="/">
          Home
        </Link>
      </li>
      <li className={parentMenu === "about" ? "current-menu-item" : ""}>
        <Link
          to="/about"
          className={activeMenu === "/about" ? "active-menu" : ""}
        >
          About
        </Link>
      </li>
      <li
        className={
          parentMenu === "service"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">Services</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/service/software-development"
              className={
                activeMenu === "/software-development" ? "active-menu" : ""
              }
            >
              Software Development 
            </Link>
          </li>
          <li>
            <Link
              to="/service/web-development"
              className={activeMenu === "/web-development" ? "active-menu" : ""}
            >
              Web Development
            </Link>
          </li>
          <li>
            <Link
              to="/service/mobile-app-development"
              className={
                activeMenu === "/mobile-app-development" ? "active-menu" : ""
              }
            >
              Mobile App Development
            </Link>
          </li>
          <li>
            <Link
              to="/service/api-integration"
              className={
                activeMenu === "/api-integration" ? "active-menu" : ""
              }
            >
              API Integration
            </Link>
          </li>
          <li>
            <Link
              to="/service/digital-marketing"
              className={activeMenu === "/digital-marketing" ? "active-menu" : ""}
            >
              Digital Marketing
            </Link>
          </li>
          <li>
            <Link
              to="/service/e-commerce-development"
              className={activeMenu === "/e-commerce-development" ? "active-menu" : ""}
            >
              E-Commerce Development
            </Link>
          </li>
        </ul>
      </li>
      <li
        className={
          parentMenu === "product"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">Products</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/product/school-management-system"
              className={activeMenu === "/school-management-system" ? "active-menu" : ""}
            >
              School Management System
            </Link>
          </li>
          <li>
            <Link
              to="/product/accounts-and-inventory-management"
              className={activeMenu === "/accounts-and-inventory-management" ? "active-menu" : ""}
            >
              Accounts &amp; Inventory Management
            </Link>
          </li>
          <li>
            <Link
              to="/product/restaurant-management-system"
              className={activeMenu === "/restaurant-management-system" ? "active-menu" : ""}
            >
              Restaurant Management System
            </Link>
          </li> 
          <li>
            <Link
              to="/product/hotel-management-system"
              className={activeMenu === "/hotel-management-system" ? "active-menu" : ""}
            >
              Hotel Management System
            </Link>
          </li>
          <li>
            <Link
              to="/product/hospital-management-system"
              className={activeMenu === "/hospital-management-system" ? "active-menu" : ""}
            >
              Hospital Management System
            </Link>
          </li>
          <li>
            <Link
              to="/product/customer-relationship-management"
              className={activeMenu === "/customer-relationship-management" ? "active-menu" : ""}
            >
              Customer Relationship Management
            </Link>
          </li>
        </ul>
      </li>
      <li
        className={
          parentMenu === "support"
            ? "menu-item-has-children current-menu-item"
            : "menu-item-has-children"
        }
      >
        <Link to="#">IT Remote Support</Link>
        <ul className="sub-menu">
          <li>
            <Link
              to="/support/project-support"
              className={activeMenu === "/project-support" ? "active-menu" : ""}
            >
              Project Support
            </Link>
          </li>
          <li>
            <Link
              to="/support/job-support"
              className={activeMenu === "/job-support" ? "active-menu" : ""}
            >
              Job Support
            </Link>
          </li>
          <li>
            <Link
              to="/support/cloud-support"
              className={activeMenu === "/cloud-support" ? "active-menu" : ""}
            >
             Cloud Support 
            </Link>
          </li>
        </ul>
      </li>
      <li className={parentMenu === "contact" ? "current-menu-item" : ""}>
        <Link
          to="/contact"
          className={activeMenu === "/contact" ? "active-menu" : ""}
        >
          Contact
        </Link>
      </li>
    </React.Fragment>
  );
};

export default MenuItems;
