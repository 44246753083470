import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";

// About Image
import aboutImg1 from "../../assets/img/service/custom/2.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Categories Image
import categoryImg from "../../assets/img/pricing/4.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/2.jpg";

const WebDevelopment = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/web-development"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Web Development"
        pageCategory="Services"
        pageName="Web Development"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="Web Development"
                titleClass="title"
                title="We Help to Implement Your Ideas into Automation"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="We build dynamic experiences and a wide variety of custom web development solutions. Over 10  years of experience make us an excellent and feasible Web Development Company."
                secondDescClass="desc"
                secondDescription="We are specialized in developing multiple applications to improve the client&#39;s productivity, and market value and earn more profit."
              />
              <SectionTitle
                sectionClass="sec-title4"
                secondDescClass="desc"
                secondDescription="Our main concern is to provide excellent web solutions to our respective clients. Browse our website and search for the services we are offering for the convenience of our clients."
              />
              {/* Section Title End */}
              <div className="btn-part mt-46">
                <Link to="/contact" className="readon learn-more">
                  Contact Us Now
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* Category Section Start */}
      <div className="rs-pricing gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Categories"
            titleClass="title title2"
            title="Services In All Categories"
          />
          <div className="row">
            <div className="col-lg-6 mb-30">
              <div className="pricing-table alignLeft">
                <div className="row">
                  <div className="col-md-4 col-xs-4">
                    <div className="pricing-icon">
                      <img src={categoryImg} alt="Web Portal Development" />
                    </div>
                  </div>
                  <div className="col-md-8 col-xs-8">
                    <h4 className="mb-5">Web Portal Development</h4>
                    <p className="table-period">
                      <i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Enterprise Portal Development
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Job Portal Development
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Online Travel Portal Development
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Real Estate Portal Development
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-30">
              <div className="pricing-table alignLeft">
                <div className="row">
                  <div className="col-md-4 col-xs-4">
                    <div className="pricing-icon">
                      <img src={categoryImg} alt="E-commerce Solution" />
                    </div>
                  </div>
                  <div className="col-md-8 col-xs-8">
                    <h4 className="mb-5">E-commerce Solution</h4>
                    <p className="table-period">
                      <i className="flaticon-right-arrow">&nbsp;&nbsp;</i>E-commerce Application Development
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>E-commerce Platform Development
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>E-commerce Enterprise Development
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>B2B and Multi-Vendor Marketplace
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-30">
              <div className="pricing-table alignLeft">
                <div className="row">
                  <div className="col-md-4 col-xs-4">
                    <div className="pricing-icon">
                      <img src={categoryImg} alt="Mobile Friendly Web Development" />
                    </div>
                  </div>
                  <div className="col-md-8 col-xs-8">
                    <h4 className="mb-5">Mobile Friendly Web Dev.</h4>
                    <p className="table-period">
                      <i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Web Backend & API Development
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>SOAP or REST Web Services Development
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Controlled Quality and Process Dev.
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Privacy and Confidentiality
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-6 mb-30">
              <div className="pricing-table alignLeft">
                <div className="row">
                  <div className="col-md-4 col-xs-4">
                    <div className="pricing-icon">
                      <img src={categoryImg} alt="Custom Web Development" />
                    </div>
                  </div>
                  <div className="col-md-8 col-xs-8">
                    <h4 className="mb-5">Custom Web Development</h4>
                    <p className="table-period">
                      <i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Custom Image Web Development
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Work-Flow & Project Management
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Application Integration Service
                      <br /><i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Custom Business Application
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Category Section End */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default WebDevelopment;
