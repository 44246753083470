import React from "react";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import Footer from "../../components/Layout/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";

import SectionTitle from "../../components/Common/SectionTitle";
import CardTwo from "../../components/Card/CardTwo";
import Technology from "../../components/Technology/index";

// About Project Support
import aboutImg1 from "../../assets/img/about/about-4.png";
import danceEffectImg1 from "../../assets/img/about/effect-1.png";

// Benefits Image
import benefitImg1 from "../../assets/img/pricing/style2/1.png";
import benefitImg2 from "../../assets/img/pricing/style2/2.png";
import benefitImg3 from "../../assets/img/pricing/style2/3.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const JobSupport = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="support"
        secondParentMenu="supports"
        activeMenu="/job-support"
      />

      <main>
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          breadcrumbsImg={breadcrumbsImg}
          pageTitle="Job Support / Technical Assistance"
          pageCategory="IT Remote Support"
          pageName="Job Support Service"
        />
        {/* breadcrumb-area-end */}

        {/* about-area-start */}
        <div
          id="rs-about"
          className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 md-mb-30">
                <div className="rs-animation-shape">
                  <div className="images">
                    <img src={aboutImg1} alt="About Us" />
                  </div>
                  <div className="middle-image2">
                    <img className="dance" src={danceEffectImg1} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pl-60 md-pl-14">
                <div className="contact-wrap">
                  {/* Section Title Start */}
                  <SectionTitle
                    sectionClass="sec-title mb-20"
                    subtitleClass="sub-text style-bg"
                    subtitle="Unable to work? Your computer freezes?"
                    titleClass="title pb-20"
                    title="We&#39;ll help you get back to work. Fast and Qualitatively."
                    descClass="desc pb-20"
                    description="Technology changes rapidly, and IT professionals are usually too busy to keep up with the pace due to their hectic work schedules. These days companies are expecting a lot from an employee and the employee will have to meet the expectation of their employer by giving consistent results. But sometimes it will be difficult to meet the expectations of the employer due to various unavoidable factors."
                    secondDescClass="margin-0 pb-10"
                    secondDescription="Our Technical support engineers are responsible for answering incoming phone calls and e-mails from customers and addressing their questions and concerns regarding the company's costs and service technologies, as well as troubleshooting any technical problems they may have."
                  />              
                  <SectionTitle
                    sectionClass="sec-title"
                    secondDescClass="margin-0 pb-16"
                    secondDescription="So to help employees narrow the gap between expectation and delivery, we have introduced Online Job Support or you may call it On the Job Support for IT Professionals."
                  />
                  <SectionTitle
                    sectionClass="sec-title"
                    secondDescClass="margin-0 pb-16"
                    secondDescription="Our Technical support engineers are responsible for answering incoming phone calls and e-mails from customers and addressing their questions and concerns regarding the company's costs and service technologies, as well as troubleshooting any technical problems they may have."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* about-area-end */}

        {/* feature-area-start */}
        <div className="rs-pricing style2 pt-120 pb-144 md-pt-80 md-pb-80">
          <div className="container">
            <SectionTitle
              sectionClass="sec-title2 text-center mb-46"
              subtitleClass="sub-text style-bg"
              subtitle="Features"
              titleClass="title title2"
              title="How this service is beneficial?"
            />
            <div className="row">
              <div className="col-lg-12 mb-30">
                <CardTwo
                  itemClass="pricing-table new-style2"
                  badge="50+"
                  itemImage={benefitImg1}
                  title="50+ Technology On-Job Support"
                  description="We help IT, professionals, by providing them with online On-Job Support in 50+ technologies. If you have any difficulty or you need an expert to guide you to complete your project then Steema Software will be the right choice for you. Our expert consultants will connect with you remotely to solve your complex project requirements in real time."
                />
              </div>

              <div className="col-lg-12 mb-30">
                <CardTwo
                  itemClass="pricing-table new-style2"
                  badge="100%"
                  itemImage={benefitImg2}
                  title="Real time Working Professionals"
                  description="As real-time working professionals, our employees have extensive subject experience. They will not only help you in solving your problem but handhold you to do it in the most efficient way so that next time you can do it on your own. As part of our support, we guarantee that you will receive 100% explained code and programs."
                />
              </div>

              <div className="col-lg-12 mb-30">
                <CardTwo
                  itemClass="pricing-table new-style2"
                  badge="100+"
                  itemImage={benefitImg3}
                  title="Reliable and Affordable Service over 100 Countries"
                  description="Our services are very reliable and affordable. We provide Online Job Support from India to professionals across 100 countries. Because of the reliable services we are trusted by our many corporate clients across the globe. Employees of companies like Amazon, Capgemini, Wavex, Accenture, Deccansoft, Deloitte, and TCS are being offered our services."
                />
              </div>
            </div>
          </div>
        </div>
        {/* feature-area-end */}

        <Technology />

        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}
      </main>
      <Footer />
      <SearchModal />
    </React.Fragment>
  );
};

export default JobSupport;
