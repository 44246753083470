import React from "react";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import ContactForm from "../../components/Contact/ContactForm";
import ContactInfo from "../../components/Contact/ContactInfo";
import ScrollToTop from "../../components/Common/ScrollTop";

import bannerbg from "../../assets/img/breadcrumbs/3.jpg";

const ContactMain = () => {
  return (
    <React.Fragment>
      {/* SiteBreadcrumb */}
      <SiteBreadcrumb
        pageTitle="Contact"
        pageName="Contact"
        breadcrumbsImg={bannerbg}
      />
      {/* SiteBreadcrumb */}

      {/* Contact Section Start */}
      <div className="rs-contact pt-120 md-pt-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 md-mb-60">
              <ContactInfo />
            </div>
            <div className="col-lg-8 pl-70 md-pl-14">
              <div className="contact-widget">
                <SectionTitle
                  sectionClass="sec-title2 mb-40"
                  subtitleClass="sub-text contact mb-14"
                  subtitle="Get In Touch"
                  titleClass="title testi-title"
                  title="Fill The Form Below"
                />

                {/* Contact Form */}
                <ContactForm />
                {/* Contact Form */}
              </div>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="map-canvas pt-120 md-pt-80 col-12">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15552.257907528563!2d77.6445757!3d12.967726!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3bae1558655f308d%3A0xec3eb9ce73eb5401!2sSTEEMA%20SOFTWARE%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1699947006187!5m2!1sen!2sin"
            ></iframe>
          </div>
          {/* <div className="map-canvas pt-120 md-pt-80 col-6">
            <iframe
              title="Google Map"
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d15225.197458286919!2d78.4647604!3d17.44538!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x0%3A0x1d9d05b11bba889!2sSteema%20Software%20Private%20Limited!5e0!3m2!1sen!2sin!4v1659641848318!5m2!1sen!2sin"
            ></iframe>
          </div> */}
        </div>

        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}
      </div>
      {/* Contact Section End */}
    </React.Fragment>
  );
};

export default ContactMain;
