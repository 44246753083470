import React from "react";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import WorkingIndustry from "../../components/Industry/index";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const Industry = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="industry"
        activeMenu="/industry"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Industries"
        titleClass="page-title"
        pageName="Industries"
      />
      {/* breadcrumb-area-end */}
      <WorkingIndustry />
      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default Industry;
