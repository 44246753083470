import React,{Fragment,useState} from "react";
import axios from "axios";



const ContactForm = (props) => {
  const { submitBtnClass } = props;
  const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [location, setYourLocation] = useState('');
    const [message, setYourmessageHere] = useState('');
    const [subject, setSubject] = useState('');
    const [website, setWebsite] = useState('');
    

    const handleNameChange = (value) => {
        setName(value);
    };
    const handleemailChange = (value) => {
        setEmail(value);
    };
    const handlephoneChange = (value) => {
        setPhone(value);
    };
    const handlelocationChange = (value) => {
        setYourLocation(value);
    };
    const handlemessageChange = (value) => {
        setYourmessageHere(value);
    };
    const handlesubjectChange = (value) => {
        setSubject(value);
    };
    const handlewebsiteChange = (value) => {
      setWebsite(value);
  };
  
    const handlesave = () => {
        const data = {
            Name: name,
            PhoneNumber: phone,
            Email: email,   
            Website:website,
            Subject:subject,
            Location:location,
            message:message         
           
        };
        const url = 'https://localhost:7101/api/Cards';
        axios.post(url, data).then((result) => {
            alert(result.data);
        }).catch((error) => {
            alert(error);
        })

    }
  
  return (
    <Fragment>
    <form id="contact-form" action="#">
      <div className="row">

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="name"
            name="name"
            placeholder="Name" onChange={(e) => handleNameChange(e.target.value)}
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="email"
            name="email"
            placeholder="E-Mail" onChange={(e) => handleemailChange(e.target.value)}
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="phone"
            name="phone"
            placeholder="Phone Number" onChange={(e) => handlephoneChange(e.target.value)}
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="website"
            name="website"
            placeholder="Your Website" onChange={(e) => handlewebsiteChange(e.target.value)}
            required
          />
        </div>

        <div className="col-md-6 mb-30">
          <select
            className="from-control"
            type="text"
            id="subject"
            name="subject" onChange={(e) => handlesubjectChange(e.target.value)}
            required
          >
            
            <option>Technical Assistance</option>
            <option>Cloud Assistance</option>
            <option>Product/Software Demo</option>
            <option>Design & Development</option>
            <option>Feedback</option>
            <option>Complaint</option>
          </select>
        </div>

        <div className="col-md-6 mb-30">
          <input
            className="from-control"
            type="text"
            id="location"
            name="location"
            placeholder="Your Location" onChange={(e) => handlelocationChange(e.target.value)}
            required
          />
        </div>

        <div className="col-12 mb-30">
          <textarea
            className="from-control"
            id="message"
            name="message"
            placeholder="Your message Here" onChange={(e) => handlemessageChange(e.target.value)}
            required
          ></textarea>
        </div>
      </div>
      <div className="btn-part">
        <button
          className={
            submitBtnClass ? submitBtnClass : "readon learn-more submit"
          }
          type="button"
          onClick={()=>handlesave()} 
        >
          Submit Now
        </button> 
      </div>
    </form>
    </Fragment>
  );
};

export default ContactForm;
