import React from "react";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import Footer from "../../components/Layout/Footer";
import ScrollToTop from "../../components/Common/ScrollTop";
import Technology from "../../components/Technology/index";
import SectionTitle from "../../components/Common/SectionTitle";
import CardOne from "../../components/Card/CardOne";

// About Project Support
import aboutImg1 from "../../assets/img/about/about-5.png";
import danceEffectImg1 from "../../assets/img/about/effect-1.png";
import effectImg1 from "../../assets/img/about/dotted-3.png";
import effectImg2 from "../../assets/img/about/shape3.png";

// Pricing Image
import silverImg from "../../assets/img/pricing/1.png";
import goldImg from "../../assets/img/pricing/2.png";
import platinumImg from "../../assets/img/pricing/3.png";
import basicImg from "../../assets/img/pricing/4.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const ProjectSupport = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="support"
        secondParentMenu="supports"
        activeMenu="/project-support"
      />

      <main>
        {/* breadcrumb-area-start */}
        <SiteBreadcrumb
          breadcrumbsImg={breadcrumbsImg}
          pageTitle="Project Support / IT Remote Support"
          pageCategory="IT Remote Support"
          pageName="Project Support / Technology Support / IT Remote Support"
        />
        {/* breadcrumb-area-end */}

        {/* about-area-start */}
        <div
          id="rs-about"
          className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
        >
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 md-mb-30">
                <div className="rs-animation-shape">
                  <div className="images">
                    <img src={aboutImg1} alt="About Us" />
                  </div>
                  <div className="middle-image2">
                    <img className="dance" src={danceEffectImg1} alt="" />
                  </div>
                </div>
              </div>
              <div className="col-lg-6 pl-60 md-pl-14">
                <div className="contact-wrap">
                  {/* Section Title Start */}
                  <SectionTitle
                    sectionClass="sec-title mb-30"
                    subtitleClass="sub-text style-bg"
                    subtitle="Unable to work? Your computer freezes?"
                    titleClass="title pb-36"
                    title="We’ll help you get back to work. Fast and Qualitatively."
                    descClass="desc pb-34"
                    description="Our Technical Support teams aid your business in enhancing end-customer experiences by supporting, maintaining, and enhancing your products or platforms. As an experienced Technical Support partner, we will work with you to establish well-defined process strategies that support your business objectives."
                    secondDescClass="margin-0 pb-16"
                    secondDescription="Big companies that were once leaders in their fields have gone out of business and been taken over by their former small rivals. Now the question comes to mind what went wrong with these stalwarts? If we dig deep into this then we will realize that not adopting the latest technology is the major factor that affects the growth of these big companies. The technology landscape is rapidly changing, and companies can't ignore it. They have to adopt new technologies for their growth."
                  />
                  <SectionTitle
                    sectionClass="sec-title mb-10"
                    secondDescClass="margin-0 pb-16"
                    secondDescription="The adoption of new technologies is not just a concern for companies, but also for employees. Every employee has to upgrade their skills regularly and adapt to the new technical environment. If someone will not embrace new technology then they will be left behind and others might take their positions."
                  />
                </div>
              </div>
            </div>
            <div className="shape-image">
              <img className="top dance" src={effectImg2} alt="" />
              <img className="bottom dance" src={effectImg1} alt="" />
            </div>

            <div className="row align-items-center">
              <div className="col-lg-12 pl-60 md-pl-14">
                <div className="contact-wrap">
                  {/* Section Title Start */}
                  <SectionTitle
                    sectionClass="sec-title mb-30 mt-40"
                    descClass="desc pb-34"
                    description="As new technologies are emerging in the technological world, it is becoming increasingly difficult for employees to keep up with these rapid changes. So now the question is how employees will do their job with precision without becoming masters in the subject."
                    secondDescClass="margin-0 pb-16"
                    secondDescription="To cater to this problem Steema Software Private Limited has started remote support services for companies as well as for individual employees. Our experts will guide them to solve their problems and complete their tasks on time."
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* about-area-end */}

        {/* pricing-area-start */}
        <div className="rs-pricing style2 pt-120 pb-144 md-pt-80 md-pb-80">
          <div className="container">
            <SectionTitle
              sectionClass="sec-title2 text-center mb-46"
              subtitleClass="sub-text style-bg"
              subtitle="Pricing"
              titleClass="title title2"
              title="Our Flexible Plans"
              secondDescClass="margin-0 pb-16"
              secondDescription="We have designed some very flexible plans which will help the employees to choose as per their needs."
            />
            <div className="row">
              <div className="col-lg-6 mb-30">
                <CardOne
                  itemClass="pricing-table new-style2"
                  badge="PPT"
                  itemImage={basicImg}
                  title="Pay Per Ticket"
                  description="If you want our experts to solve specific technical issues then you can raise a ticket by contacting us by either Email or Phone. So this plan is for those who want our services on a ticket basis."
                />
              </div>

              <div className="col-lg-6 mb-30">
                <CardOne
                  itemClass="pricing-table new-style2"
                  badge="PPH"
                  itemImage={goldImg}
                  title="Pay Per Hour"
                  description="If you have some technical issue in your job and you want our experts for continuous guidance in solving the issue with the solution elaboration then you can opt for this hourly plan."
                />
              </div>

              <div className="col-lg-6 mb-30">
                <CardOne
                  itemClass="pricing-table new-style2"
                  badge="PPM"
                  itemImage={platinumImg}
                  title="Pay Per Month"
                  description="If you have some major technical issues in your job and you want our experts for continuous guidance in solving all the issues then you can opt for this monthly plan."
                />
              </div>

              <div className="col-lg-6 mb-30">
                <CardOne
                  itemClass="pricing-table new-style2"
                  badge="ESP"
                  itemImage={silverImg}
                  title="Emergency Support Plan"
                  description="If you are looking for some immediate solutions to solving any issue then this plan is best for you. Our experts will be available on an immediate basis to solve your query."
                />
              </div>
            </div>

            <p>
              Whether implementing new products or upgrading your current
              environment, Steema can provide remote functional and technical
              resources to ensure your project&#39;s success at a lower cost than
              having an on-site consultant and without the skill gap, time zone,
              and communication issues experienced when off-shoring project
              roles.
              <br />
              <b>The benefits of our service include:</b>
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Value for money, with the right package and support expertise to
              match your exact needs
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Improved cost control, only paying for the support you need
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Flexible packages, able to support changing business requirements
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>A
              dedicated support consultant who knows your business and your
              systems, ensuring efficient and cost-effective support
            </p>
          </div>
        </div>
        {/* pricing-area-end */}

        <Technology />

        {/* scrolltop-start */}
        <ScrollToTop />
        {/* scrolltop-end */}
      </main>
      <Footer />
      <SearchModal />
    </React.Fragment>
  );
};

export default ProjectSupport;
