import React from "react";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";
import CardThree from "../../components/Card/CardThree";

// About Image
import aboutImg1 from "../../assets/img/product/custom/4.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Business Image
import hotelImg1 from "../../assets/img/product/hotel/1.png";
import hotelImg2 from "../../assets/img/product/hotel/2.png";
import hotelImg3 from "../../assets/img/product/hotel/3.png";
import hotelImg4 from "../../assets/img/product/hotel/4.png";
import hotelImg5 from "../../assets/img/product/hotel/5.png";
import hotelImg6 from "../../assets/img/product/hotel/6.png";

// Features Image
import hotelFeatureImg1 from "../../assets/img/product/hotel/feature/1.png";
import hotelFeatureImg2 from "../../assets/img/product/hotel/feature/2.png";
import hotelFeatureImg3 from "../../assets/img/product/hotel/feature/3.png";
import hotelFeatureImg4 from "../../assets/img/product/hotel/feature/4.png";
import hotelFeatureImg5 from "../../assets/img/product/hotel/feature/5.png";
import hotelFeatureImg6 from "../../assets/img/product/hotel/feature/6.png";
import hotelFeatureImg7 from "../../assets/img/product/hotel/feature/7.png";
import hotelFeatureImg8 from "../../assets/img/product/hotel/feature/8.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const HotelManagementSystem = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="product"
        secondParentMenu="products"
        activeMenu="/hotel-management-system"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Hotel Management System"
        pageCategory="Products"
        pageName="Hotel Management System"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about rs-pricing pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-40 md-pl-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="Hotel/Resort Management Software"
                titleClass="title"
                title="Monitor hotel operations at any time and from anywhere with any device."
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-10"
                descClass="desc desc-big"
                description="With our development skills, we try to understand the requirements of our clients well and develop features that offer a complete, yet modular software to optmize the operations of individual or a chain of hotels."
                secondDescClass="desc"
                secondDescription="We provide fully integrated seam-less solution that includes all hotel technology products, can help hotels increase revenue and reduce inefficiencies. PMS, RMS, CM, POS, Reviews, Competition, Analytics, Booking Engine, all are availabel at single place."
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                secondDescClass="desc"
                secondDescription="This will help you to run your hotel operations efficiently. Enabled with a mobile application where guests perform all their operations right from check-in to payment."
              />
              {/* Section Title End */}
            </div>
          </div>
        </div>
        <div className="rs-services style5 pt-60 md-pt-40">
          <div className="container">
            <div className="row mb-30">
              <div className="col-md-12">
                <p>
                  <b>This hotel management software is perfect for</b>
                </p>
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={hotelImg1}
                  description="Hotels / Motels"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={hotelImg2}
                  description="Guesthouses / B&amp;B&#39;s"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={hotelImg3}
                  description="Apartments / Condos"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={hotelImg4}
                  description="Campgrounds / RV Parks"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={hotelImg5}
                  description="Retreats / Resorts"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={hotelImg6}
                  description="Staff / Student Residences"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* Category Section Start */}
      <div className="rs-pricing gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 mb-10"
            subtitleClass="sub-text style-bg"
            subtitle="Benefits &amp; Features"
          />
          <div className="row mb-30">
            <div className="col-md-12">
              <p className="mb-5">
                <b>
                  There are a variety of benefits offered by this software to
                  its diverse group of users:
                </b>
              </p>
              <ul>
                <li>
                  <b>
                    <i className="flaticon-play-button-1">&nbsp;&nbsp;</i>{" "}
                    Optimize Revenue:
                  </b>{" "}
                  Direct two-way connect to channels and single image
                  availability. Manage Rates in real-time.
                </li>
                <li>
                  <b>
                    <i className="flaticon-play-button-1">&nbsp;&nbsp;</i> More
                    Direct Bookings:
                  </b>{" "}
                  Let guests book directly from your website over mobile devices
                  by making payments and getting instant confirmations.
                </li>
                <li>
                  <b>
                    <i className="flaticon-play-button-1">&nbsp;&nbsp;</i> Avoid
                    Cloud Disasters:
                  </b>{" "}
                  User-based access, additional IP-based authorization. Hourly
                  critical data backups are sent to the system admin.
                </li>
                <li>
                  <b>
                    <i className="flaticon-play-button-1">&nbsp;&nbsp;</i>{" "}
                    Protect Your IT:
                  </b>{" "}
                  Easy to use, no more dedicated IT people, basic hardware
                  requirement.
                </li>
                <li>
                  <b>
                    <i className="flaticon-play-button-1">&nbsp;&nbsp;</i> Green
                    IT:
                  </b>{" "}
                  Manage guest-facing to admin activity over digital formats and
                  soft copies.
                </li>
                <li>
                  <b>
                    <i className="flaticon-play-button-1">&nbsp;&nbsp;</i> No
                    More Lost Reservations:
                  </b>{" "}
                  No more emails and manual updates, get reservations
                  dynamically into PMS with alerts.
                </li>
                <li>
                  <b>
                    <i className="flaticon-play-button-1">&nbsp;&nbsp;</i> No
                    More Lost Sales:
                  </b>{" "}
                  Multiple options for guests to book rooms, real-time rates,
                  and availability, and instant payment capture.
                </li>
                <li>
                  <b>
                    <i className="flaticon-play-button-1">&nbsp;&nbsp;</i>{" "}
                    Social Media And Reputation Management:
                  </b>{" "}
                  Get all your guest feedback in a single screen, see the
                  feedback heat map, and understand and improve your guest
                  satisfaction score.
                </li>
              </ul>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="mt-50">
                <b>Core features of Steema Hotel Management System:</b>
              </p>
            </div>
            <div className="col-md-2 mb-10">
              <CardThree
                itemImage={hotelFeatureImg1}
                description="Clean and Simple Booking Calender"
              />
            </div>
            <div className="col-md-2 mb-10">
              <CardThree
                itemImage={hotelFeatureImg2}
                description="Room Management"
              />
            </div>
            <div className="col-md-2 mb-10">
              <CardThree
                itemImage={hotelFeatureImg3}
                description="Customizable Invoices"
              />
            </div>
            <div className="col-md-2 mb-10">
              <CardThree
                itemImage={hotelFeatureImg4}
                description="Online Booking Engine"
              />
            </div>
            <div className="col-md-2 mb-10">
              <CardThree itemImage={hotelFeatureImg5} description="Robust Reports" />
            </div>
            <div className="col-md-2 mb-10">
              <CardThree
                itemImage={hotelFeatureImg6}
                description="Employee Management"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-md-1"></div>
            <div className="col-md-2 mb-10">
              <CardThree
                itemImage={hotelFeatureImg7}
                description="Housekeeping Tools"
              />
            </div>
            <div className="col-md-2 mb-10">
              <CardThree
                itemImage={hotelFeatureImg8}
                description="Secure Payment Processing"
              />
            </div>
          </div>
        </div>
      </div>
      {/* Category Section End */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default HotelManagementSystem;
