import React, { useState } from "react";
import { Link } from "react-router-dom";

const RSMobileMenu = ({ setMenuOpen, menuOpen, activeMenu }) => {
  const [home, setHome] = useState(false);
  const [about, setAbout] = useState(false);
  const [services, setServices] = useState(false);
  const [products, setProducts] = useState(false);
  const [supports, setSupports] = useState(false);

  const openMobileMenu = (menu) => {
    if (menu === "home") {
      setHome(!home);
      setAbout(false);
      setServices(false);
      setProducts(false);
      setSupports(false);
    } else if (menu === "about") {
      setHome(false);
      setAbout(!about);
      setServices(false);
      setProducts(false);
      setSupports(false);
    } else if (menu === "services") {
      setHome(false);
      setAbout(false);
      setServices(!services);
      setProducts(false);
      setSupports(false);
    } else if (menu === "products") {
      setHome(false);
      setAbout(false);
      setServices(false);
      setProducts(!products);
      setSupports(false);
    } else if (menu === "supports") {
      setHome(false);
      setAbout(false);
      setServices(false);
      setProducts(false);
      setSupports(!supports);
    }
  };

  return (
    <div className="container relative">
      <div className={menuOpen ? "mobile-menu-part open" : "mobile-menu-part"}>
        <div className="mobile-menu">
          <ul className="nav-menu">
            <li>
              <Link
                to="/home"
                className={activeMenu === "/" ? "active-menu" : ""}
              >
                Home
              </Link>
            </li>
            <li>
              <Link
                to="/about"
                className={activeMenu === "/about" ? "active-menu" : ""}
              >
                About
              </Link>
            </li>
            <li
              className={
                services
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("services");
                }}
              >
                Services
              </Link>
              <ul className={services ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/service/software-development"
                    className={
                      activeMenu === "/software-development"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Software Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="/service/web-development"
                    className={
                      activeMenu === "/web-development" ? "active-menu" : ""
                    }
                  >
                    Web Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="/service/mobile-app-development"
                    className={
                      activeMenu === "/mobile-app-development"
                        ? "active-menu"
                        : ""
                    }
                  >
                    Mobile App Development
                  </Link>
                </li>
                <li>
                  <Link
                    to="/service/api-integration"
                    className={
                      activeMenu === "/api-integration" ? "active-menu" : ""
                    }
                  >
                    API Integration
                  </Link>
                </li>
                <li>
                  <Link
                    to="/service/digital-marketing"
                    className={
                      activeMenu === "/digital-marketing" ? "active-menu" : ""
                    }
                  >
                    Digital Marketing
                  </Link>
                </li>
                <li>
                  <Link
                    to="/service/e-commerce-development"
                    className={
                      activeMenu === "/e-commerce-development"
                        ? "active-menu"
                        : ""
                    }
                  >
                    E-Commerce Development
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                products
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("products");
                }}
              >
                Products
              </Link>
              <ul className={products ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/product/school-management-system"
                    className={activeMenu === "/school-management-system" ? "active-menu" : ""}
                  >
                    School Management System
                  </Link>
                </li>
                <li>
                  <Link
                    to="/product/accounts-and-inventory-management"
                    className={
                      activeMenu === "/accounts-and-inventory-management" ? "active-menu" : ""
                    }
                  >
                    Accounts &amp; Inventory Management
                  </Link>
                </li>
                <li>
                  <Link
                    to="/product/restaurant-management-system"
                    className={activeMenu === "/restaurant-management-system" ? "active-menu" : ""}
                  >
                    Restaurant Management System
                  </Link>
                </li>
                <li>
                  <Link
                    to="/product/hotel-management-system"
                    className={activeMenu === "/hotel-management-system" ? "active-menu" : ""}
                  >
                    Hotel Management System
                  </Link>
                </li>
                <li>
                  <Link
                    to="/product/customer-relationship-management"
                    className={activeMenu === "/customer-relationship-management" ? "active-menu" : ""}
                  >
                    Customer Relationship Management
                  </Link>
                </li>
              </ul>
            </li>
            <li
              className={
                supports
                  ? "menu-item-has-children current-menu-item"
                  : "menu-item-has-children"
              }
            >
              <Link
                to="#"
                onClick={() => {
                  openMobileMenu("supports");
                }}
              >
                IT Remote Support
              </Link>
              <ul className={supports ? "sub-menu current-menu" : "sub-menu"}>
                <li>
                  <Link
                    to="/support/project-support"
                    className={activeMenu === "/blog" ? "active-menu" : ""}
                  >
                    Project Support
                  </Link>
                </li>
                <li>
                  <Link
                    to="/support/job-support"
                    className={
                      activeMenu === "/job-support" ? "active-menu" : ""
                    }
                  >
                    Job Support
                  </Link>
                </li>
                <li>
                  <Link
                    to="/support/cloud-support"
                    className={
                      activeMenu === "/cloud-support" ? "active-menu" : ""
                    }
                  >
                    Cloud Support
                  </Link>
                </li>
              </ul>
            </li>
            <li>
              <Link
                to="/contact"
                className={activeMenu === "/contact" ? "active-menu" : ""}
              >
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default RSMobileMenu;
