import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import SingleService from "../../components/Service/SingleService";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ProgressBar from "../../components/Elements/Progress/ProgressBar";
import ScrollToTop from "../../components/Common/ScrollTop";

// Service Image
import serviceIcon1 from "../../assets/img/service/style10/1.png";
import serviceIcon2 from "../../assets/img/service/style10/2.png";
import serviceIcon3 from "../../assets/img/service/style10/3.png";

// About Image
import aboutImg1 from "../../assets/img/service/custom/1.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Choose Us Image
import whyChooseUsImg from "../../assets/img/choose/3.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const SoftwareDevelopment = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/software-development"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Software Development"
        pageCategory="Services"
        pageName="Software Development"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="Software Development"
                titleClass="title"
                title="We Help to Implement Your Ideas into Automation"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="Providing you with powerful and adaptable digital solutions that satisfy your needs today and open up opportunities tomorrow, we help you in transforming your business."
                secondDescClass="desc"
                secondDescription="As a leading Software Development Outsourcing Company in India, we help your business achieve new heights and grow exponentially to maximize profits. We have a team of highly qualified and experienced IT professionals who can deliver the right software solution to a variety of businesses across different industries."
              />
              <SectionTitle
                sectionClass="sec-title4"
                secondDescClass="desc"
                secondDescription="With our advanced frameworks, processes, automation, and expertise we deliver outcome-driven solutions that align with your business vision."
              />
              {/* Section Title End */}
              <div className="btn-part mt-46">
                <Link to="/contact" className="readon learn-more">
                  Contact Us Now
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
              <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
          </div>

          <div className="rs-services style5 pt-120 md-pt-80">
            <div className="container">
              <div className="row">
                <div className="col-lg-4 md-mb-30">
                  <SingleService
                    itemClass="services-item"
                    serviceImage={serviceIcon1}
                    Title="Microservices Architecture"
                    Text="Architectural pattern that arranges an application as a collection of loosely-coupled"
                    serviceURL="#"
                  />
                </div>
                <div className="col-lg-4 md-mb-30">
                  <SingleService
                    itemClass="services-item"
                    serviceImage={serviceIcon2}
                    Title="CI/CD Pipeline"
                    Text="Series of steps that must be performed in order to deliver a new version of the software"
                    serviceURL="#"
                  />
                </div>
                <div className="col-lg-4">
                  <SingleService
                    itemClass="services-item"
                    serviceImage={serviceIcon3}
                    Title="Agile Approach"
                    Text="Enhances team productivity and reduces headaches by facilitating faster and simpler delivery of value"
                    serviceURL="#"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* Why Choose US Section Start */}
      <div className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 pr-46 md-pr-15 md-mb-50">
              <div className="choose-img">
                <img className="main" src={whyChooseUsImg} alt="" />
              </div>
            </div>
            <div className="col-lg-6">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title mb-30"
                subtitleClass="sub-text choose"
                subtitle="Why Choose Us"
                titleClass="title pb-20"
                title="We Are Increasing Business Success With Technology "
                descClass="desc"
                description="Over 10 years working in IT services developing software applications and mobile apps for clients all over the world."
              />
              {/* Section Title End */}
              <div className="row">
                <div className="col-lg-10">
                  {/* Skill Bar Start */}
                  <div className="rs-skillbar style1 modify1">
                    <div className="cl-skill-bar">
                      <ProgressBar
                        completed={100}
                        height={7}
                        boxbg={"#DFEDFE"}
                        figurebg={"#03228f"}
                        ProgressBox="skillbar"
                        ProgressFigure="skillbar-bar"
                        ProgressCount="skill-bar-percent"
                        ProgressParent="main-div"
                        ProgressLabel="skillbar-title"
                        label="Build Integrity & Transparency"
                        ProgressDescription="skillbar-description"
                        description="We believe in building trust and 100% transparency with our clients."
                      />
                      <ProgressBar
                        completed={99.9}
                        height={7}
                        boxbg={"#DFEDFE"}
                        figurebg={"#03228f"}
                        ProgressBox="skillbar"
                        ProgressFigure="skillbar-bar"
                        ProgressCount="skill-bar-percent"
                        ProgressLabel="skillbar-title"
                        ProgressParent="main-div"
                        label="Deliver Perfect Solution"
                        ProgressDescription="skillbar-description"
                        description="We focus on building the right product that resolves users&#39; challenges."
                      />
                      <ProgressBar
                        completed={100}
                        height={7}
                        boxbg={"#DFEDFE"}
                        figurebg={"#03228f"}
                        ProgressBox="skillbar"
                        ProgressFigure="skillbar-bar"
                        ProgressCount="skill-bar-percent"
                        ProgressLabel="skillbar-title"
                        ProgressParent="main-div"
                        label="Practice Agility"
                        ProgressDescription="skillbar-description"
                        description="We meet our clients' ever-changing needs with our agile approach."
                      />
                      <ProgressBar
                        completed={100}
                        height={7}
                        boxbg={"#DFEDFE"}
                        figurebg={"#03228f"}
                        ProgressBox="skillbar"
                        ProgressFigure="skillbar-bar"
                        ProgressCount="skill-bar-percent"
                        ProgressLabel="skillbar-title"
                        ProgressParent="main-div"
                        label="Drive Innovation"
                        ProgressDescription="skillbar-description"
                        description="We are focused on delivering client-centric innovation and value to your business."
                      />
                    </div>
                  </div>
                  {/* Skill Bar End */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Why Choose US Section End */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default SoftwareDevelopment;
