import React from "react";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";

// About Image
import aboutImg1 from "../../assets/img/service/custom/3.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

import techImg1 from "../../assets/img/technology/mobileapp/1.png";
import techImg2 from "../../assets/img/technology/mobileapp/2.png";
import techImg3 from "../../assets/img/technology/mobileapp/3.png";
import techImg4 from "../../assets/img/technology/mobileapp/4.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const MobileAppDevelopment = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/mobile-app-development"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Mobile App Development"
        pageCategory="Services"
        pageName="Mobile App Development"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="Mobile App Development"
                titleClass="title"
                title="We Help to Implement Your Ideas into Automation"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="Our mission in steema is to develop a highly-rated mobile application to help businesses grow. We build an interactive and adaptable mobile application."
                secondDescClass="desc"
                secondDescription="Increased usage of the mobile app has created a demand for a customer-centered mobile application that elevates your business to profit and usability."
              />
              <SectionTitle
                sectionClass="sec-title4"
                secondDescClass="desc"
                secondDescription="Empower your business with our Android, IOS, and Windows applications. Mobile application helps to accelerate your business growth and reduce the gap between business and customers."
              />
              {/* Section Title End */}
              <p>
                <br />
                <b>Features</b>
                <br />
                <i className="flaticon-right-arrow">&nbsp;&nbsp;</i>The mobile
                application offers a Faster and more responsive user experience.
                <br />
                <i className="flaticon-right-arrow">&nbsp;&nbsp;</i>The mobile
                application increases engagement and interactivity.
                <br />
                <i className="flaticon-right-arrow">&nbsp;&nbsp;</i>The mobile
                application provides a personalized experience.
                <br />
                <i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Mobile
                Applications help to generate brand awareness.
                <br />
                <i className="flaticon-right-arrow">&nbsp;&nbsp;</i>Mobile apps
                help to provide a location-based service.
              </p>
            </div>
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* Category Section Start */}
      <div className="rs-pricing gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Benefits &amp; Categories"
            titleClass="title title2"
            title="Services In All Categories"
          />
          <div className="row">
            <div className="col-lg-6 mb-30">
              <div className="blog-full">
                <p className="mb-5">
                  <b>Benefits</b>
                </p>
                <ul className="dots">
                  <li>Straighten customer engagement</li>
                  <li>Increase accessibility</li>
                  <li>Provide value for customers</li>
                  <li>Build brand awareness and recognition</li>
                  <li>Stand out from the competition</li>
                  <li>Cultivate customer loyalty</li>
                  <li>Unique services and payment</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-6 mb-30">
              <div className="blog-full">
                <p className="mb-5">
                  <b>Categories</b>
                </p>
                <ul className="dots">
                  <li>iOS App Development</li>
                  <li>Android App Development</li>
                  <li>Cross Platform Apps</li>
                  <li>Hybrid App Development</li>
                  <li>Mobile App Driven By Cloud</li>
                </ul>
              </div>
            </div>

            <div className="col-lg-12 col-xs-12 alignCenter">
              <p className="mb-5">
                <b>Technologies</b>
              </p>
            </div>

            <div className="col-lg-2 col-xs-6"></div>
            <div className="col-lg-2 col-xs-6">
              <div className="logo-img">
                <img src={techImg1} alt="Xamarin" />
              </div>
            </div>
            <div className="col-lg-2 col-xs-6">
              <div className="logo-img">
                <img src={techImg2} alt="React Native" />
              </div>
            </div>
            <div className="col-lg-2 col-xs-6">
              <div className="logo-img">
                <img src={techImg3} alt="Flutter" />
              </div>
            </div>
            <div className="col-lg-2 col-xs-6">
              <div className="logo-img">
                <img src={techImg4} alt="Android Studio" />
              </div>
            </div>
            <div className="col-lg-2 col-xs-6"></div>
          </div>
        </div>
      </div>
      {/* Category Section End */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default MobileAppDevelopment;
