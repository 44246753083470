import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CardOne from "../../components/Card/CardOne";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";

// About Image
import aboutImg1 from "../../assets/img/product/custom/6.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

// Features Image
import featureImg1 from "../../assets/img/pricing/1.png";
import featureImg2 from "../../assets/img/pricing/2.png";
import featureImg3 from "../../assets/img/pricing/3.png";
import featureImg4 from "../../assets/img/pricing/4.png";
import featureImg5 from "../../assets/img/pricing/style2/2.png";

const CustomerRelationshipManagement = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="product"
        secondParentMenu="products"
        activeMenu="/customer-relationship-management"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Customer Relationship Management"
        pageCategory="Products"
        pageName="Customer Relationship Management"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-40 md-pl-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="Customer Relationship Management"
                titleClass="title"
                title="Cloud-based business automation solutions"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="Steema CRM is a SaaS-based customer relationship management solution that streamlines your marketing and sales activities."
                secondDescClass="desc"
                secondDescription="Using campaigns, lead management, opportunities, and forecasting, you can reach your target audience. The centralized, easy to use, and mobile-friendly cloud CRM software provides insight into your company&#39;s marketing and sales activities and helps you close deals more effectively."
              />
              {/* Section Title End */}
              <div className="btn-part mt-46">
                <Link to="/contact" className="readon learn-more">
                  Contact Us Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* working-process-area-start */}
      <div className="rs-pricing gray-bg style2 pt-120 pb-112 md-pt-80 md-pb-72">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Features"
            titleClass="title"
            title="Core Features of Steema CRM"
          />
          <div className="row">
            <div className="col-lg-6 mb-30">
              <CardOne
                itemClass="pricing-table new-style2"
                badge="Timeline"
                itemImage={featureImg1}
                title="Complete activity timeline"
                description="With one click, see a complete history of your customer's interactions with you in an easy-to-understand timeline with a range of filters."
              />
            </div>

            <div className="col-lg-6 mb-30">
              <CardOne
                itemClass="pricing-table new-style2"
                badge="Reminders"
                itemImage={featureImg2}
                title="Automated communication reminders"
                description="Stay on top of your customer communications by scheduling them to fit in with your unique workflows. Set up handy alerts for when communications have been missed."
              />
            </div>

            <div className="col-lg-6 mb-30">
              <CardOne
                itemClass="pricing-table new-style2"
                badge="Automation"
                itemImage={featureImg3}
                title="Automated customer segmentation"
                description="Segmenting your customers helps steer your marketing. Brightpearl automatically tags or untags customers based on what they buy (or any other behaviors you select)."
              />
            </div>

            <div className="col-lg-6 mb-30">
              <CardOne
                itemClass="pricing-table new-style2"
                badge="Credits"
                itemImage={featureImg4}
                title="Manage customer credit"
                description="See, at a glance, whether a customer has an outstanding balance before processing any more sales. Keep finances in check with credit limit controls and debtor and creditor reports."
              />
            </div>

            <div className="col-lg-6 mb-30">
              <CardOne
                itemClass="pricing-table new-style2"
                badge="History"
                itemImage={featureImg5}
                title="Complete email history with customers and suppliers"
                description="Easily view a full record of emails sent and received between you and your customers and suppliers (even the ones sent outside of Brightpearl), alongside order updates in a simple, personalized contact timeline."
              />
            </div>
          </div>
        </div>
      </div>
      {/* working-process-area-end */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default CustomerRelationshipManagement;
