import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";
import SingleServiceTwo from "../../components/Service/SingleServiceTwo";

// Service Image Icons
import mainIcon1 from "../../assets/img/service/style3/main-img/1.png";
import hoverIcon1 from "../../assets/img/service/style3/hover-img/1.png";
import mainIcon2 from "../../assets/img/service/style3/main-img/2.png";
import hoverIcon2 from "../../assets/img/service/style3/hover-img/2.png";
import mainIcon3 from "../../assets/img/service/style3/main-img/3.png";
import hoverIcon3 from "../../assets/img/service/style3/hover-img/3.png";
import mainIcon4 from "../../assets/img/service/style3/main-img/4.png";
import hoverIcon4 from "../../assets/img/service/style3/hover-img/4.png";
import mainIcon5 from "../../assets/img/service/style3/main-img/5.png";
import hoverIcon5 from "../../assets/img/service/style3/hover-img/5.png";
import mainIcon6 from "../../assets/img/service/style3/main-img/6.png";
import hoverIcon6 from "../../assets/img/service/style3/hover-img/6.png";

// About Image
import aboutImg1 from "../../assets/img/service/custom/5.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const DigitalMarketing = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/digital-marketing"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Digital Marketing"
        pageCategory="Services"
        pageName="Digital Marketing"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="Digital Marketing"
                titleClass="title"
                title="Grow your business with our 360* digital marketing service."
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="With more than two million leads and more than ₹1 billion generated for its clients, Steema is the leading digital marketing organization based in India."
                secondDescClass="desc"
                secondDescription="Our performance-driven digital marketing services help your company accomplish its toughest goals, from improving conversions to growing brand awareness."
              />
              <SectionTitle
                sectionClass="sec-title4"
                secondDescClass="desc"
                secondDescription="Whether you&#39;re searching for an Internet marketing company to improve your ranking in search results, redesign your website for a marketing overhaul, or revamp your content marketing strategy, our team and customized plans are the perfect fit."
              />
              {/* Section Title End */}
              <div className="btn-part mt-46">
                <Link to="/contact" className="readon learn-more">
                  Contact Us Now
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xs-12 mt-30">
              <p className="mb-5">
                <b>
                  With our team on your company&#39;s side, we can help you to:
                </b>
              </p>
            </div>
            <div className="col-lg-6 col-xs-12">
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Increase Customer Loyalty with Frequent Communications
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Engage
              the Customer at Every Buying Stage
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Target
              the Right Audience
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Generate a Consistent Lead Pipeline
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Optimize and Obtain Better Conversion Rates
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Accurately Measure All Results
              <ul className="dots mb-5">
                <li>Cost-per-click (CPC)</li>
                <li>Return on investment (ROI)</li>
                <li>Customer acquisition cost (CAC)</li>
                <li>Customer lifetime value (CLTV)</li>
              </ul>
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Gain
              Brand Credibility
            </div>
            <div className="col-lg-6 col-xs-12">
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Gets
              You More Bang for Your Buck
              <ul className="dots mb-5">
                <li>Cost per click (CPC)</li>
                <li>Cost per thousand (CPM)</li>
                <li>Cost per lead (CPL)</li>
                <li>Cost per action (CPA)</li>
              </ul>
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Drive
              more leads
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Increase your reach
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Improve your website experience
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Levels
              the Playing Field
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Engage
              your target audience
              <br />
              <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
              Connect with your clients And more!
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* Digital Marketing Service Section Start */}
      <div className="rs-services style3 gray-bg pt-120 pb-100 md-pt-70 md-pb-60">
        <div className="container">
          <div className="row md-mb-60 text-center">
            <div className="col-lg-12 mb-60 md-mb-20">
              <SectionTitle
                sectionClass="sec-title2"
                subtitleClass="sub-text"
                subtitle="Services"
                titleClass="title testi-title"
                title="What Solutions We Provide to Our Valued Customers"
              />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item"
                prefix="01"
                mainIcon={mainIcon1}
                hoverIcon={hoverIcon1}
                Title="SEO Services"
                serviceURL="#"
                Text="We know that ranking is not the final goal, getting quality leads & multiple sales are. So, we go ahead with the traditional SEO Services & assist you in establishing your brand name and commanding the competition."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item pink-bg"
                prefix="02"
                mainIcon={mainIcon2}
                hoverIcon={hoverIcon2}
                Title="SMO Services"
                serviceURL="#"
                Text="Our SMO Services help your business to increase brand awareness on multiple social platforms such as Facebook, Twitter, LinkedIn, and many more that help you connect with your customers. Ensure your customers are connected to you."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item aqua-bg"
                prefix="03"
                mainIcon={mainIcon3}
                hoverIcon={hoverIcon3}
                Title="PPC Campaign Management"
                serviceURL="#"
                Text="Best pay-per-click management company with extensive industry experience in delivering high ROI. We are specialized in handling all features of clients' PPC campaigns, from keyword choice to bid management, ad creation & ad testing."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item paste-bg"
                prefix="04"
                mainIcon={mainIcon4}
                hoverIcon={hoverIcon4}
                Title="Lead Generation"
                serviceURL="#"
                Text="With our unique approach to digital marketing services we help you to find out your targeted audience and nurture them to become your profitable customers. We help you gain profitable customers by figuring out who your target audience is."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item purple-bg"
                prefix="05"
                mainIcon={mainIcon5}
                hoverIcon={hoverIcon5}
                Title="Conversion Rate Optimization"
                serviceURL="#"
                Text="CRO (Conversion Rate Optimization) rises the percentage of unique visitors to your website. Our expert team uses full-proof strategies that boost your business and minimize your monthly advertisement spending."
              />
            </div>
            <div className="col-lg-4 col-md-6 mb-20">
              <SingleServiceTwo
                itemClass="services-item green-bg"
                prefix="06"
                mainIcon={mainIcon6}
                hoverIcon={hoverIcon6}
                Title="Brand Identity"
                serviceURL="#"
                Text="Online Reputation Management helps to drive positive public opinion about your business, products, and services. Our expert digital marketers implement exclusive techniques to keep a positive brand identity in front of your customers."
              />
            </div>
          </div>
        </div>
        <div className="line-inner">
          <div className="line"></div>
          <div className="line"></div>
          <div className="line"></div>
        </div>
      </div>
      {/* Digital Marketing Service Section End */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section End */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default DigitalMarketing;
