import React from "react";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";
import SingleProcess from "../../components/Process/SingleProcess";
import SingleService from "../../components/Service/SingleService";

// About Image
import aboutImg1 from "../../assets/img/product/custom/2.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Working Process Images
import aniImage1 from "../../assets/img/product/ani/1.jpg";
import aniImage2 from "../../assets/img/product/ani/2.jpg";
import aniImage3 from "../../assets/img/product/ani/3.jpg";
import aniImage4 from "../../assets/img/product/ani/4.jpg";
import aniImage5 from "../../assets/img/product/ani/5.jpg";
import aniImage6 from "../../assets/img/product/ani/6.jpg";
import aniImage7 from "../../assets/img/product/ani/7.jpg";
import aniImage8 from "../../assets/img/product/ani/8.jpg";
import aniImage9 from "../../assets/img/product/ani/9.jpg";
import aniImage10 from "../../assets/img/product/ani/10.jpg";
import aniImage11 from "../../assets/img/product/ani/11.jpg";

// Benefits Image
import benefitImg1 from "../../assets/img/icon/heart.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";
import benefitImg2 from "../../assets/img/icon/portfolio.png";
import benefitImg3 from "../../assets/img/icon/tips.png";

const AccountsAndInventoryManagement = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="product"
        secondParentMenu="products"
        activeMenu="/accounts-and-inventory-management"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Accounts &amp; Inventory Management"
        pageCategory="Products"
        pageName="Accounts &amp; Inventory Management"
      />
      {/* breadcrumb-area-end */}    
      
      {/* About Section Start */}
      <div className="rs-about rs-pricing pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-40 md-pl-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="Accounts &amp; Inventory Management"
                titleClass="title"
                title="Simple to use GST billing software"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="Steema Billing is amazingly simple to use GST billing software that lets you create beautiful invoices in GST Format."
                secondDescClass="desc"
                secondDescription="A company that can offer you the best GST software at an affordable cost. We are a leading software development company in India. We’ve created Steema GST Billing, which is amazingly simple-to-use GST billing software that lets you create beautiful invoices in GST Format."
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                secondDescClass="desc"
                secondDescription="We have designed this billing ERP keeping in mind that it will reduce your compliance burden so that you can focus on your business completely."
              />
              <SectionTitle
                sectionClass="sec-title4"
                secondDescClass="desc"
                secondDescription="You can manage multiple companies in Steema Billing, It supports Unlimited Users, You can manage sales, purchases, voucher entries, export data to tally, create challan, invoice returns, purchase returns, sale order, purchase orders, inventory management, ledger report, group summary, profit and loss statement, trial balance, bank reconciliation, balance sheet, invoice report, item report & export data to excel."
              />
              {/* Section Title End */}
            </div>
          </div>
        </div>
        <div className="rs-services style5 pt-120 md-pt-80">
            <div className="container">
              <div className="row mb-30">
                <div className="col-lg-4 md-mb-30">
                  <SingleService
                    itemClass="services-item"
                    serviceImage={benefitImg1}
                    Title="24/7 Support"
                    serviceURL="#"
                    Text="Customers can get help and find answers to questions as soon as they come up"
                  />
                </div>
                <div className="col-lg-4 md-mb-30">
                  <SingleService
                    itemClass="services-item"
                    serviceImage={benefitImg2}
                    Title="Featured Data Export"
                    serviceURL="#"
                    Text="You can export the data into your required format and may use them as backup"
                  />
                </div>
                <div className="col-lg-4">
                  <SingleService
                    itemClass="services-item"
                    serviceImage={benefitImg3}
                    Title="Seamless Reporting"
                    serviceURL="#"
                    Text="All the reporting document presents the information in an utilizable format"
                  />
                </div>
              </div>
            </div>
        </div>
      </div>
      {/* About Section End */}

      {/* working-process-area-start */}
      <div className="rs-process gray-bg style2 pt-120 pb-112 md-pt-80 md-pb-72">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Modules"
            titleClass="title"
            title="List of featured modules"
          />
          <div className="row">
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage1}
                descClass="desc text-center"
                Description="Inventory Management"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage2}
                descClass="desc text-center"
                Description="Order Management"
              />
            </div>
            <div className="col-lg-3 col-sm-6 sm-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage3}
                descClass="desc text-center"
                Description="Shipping & Fulfillment"
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage4}
                descClass="desc text-center"
                Description="Warehouse Management"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage5}
                descClass="desc text-center"
                Description="Purchasing and Supplier"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage6}
                descClass="desc text-center"
                Description="Multi-Channel Store Creation"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage7}
                descClass="desc text-center"
                Description="Point-of-sale"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage8}
                descClass="desc text-center"
                Description="Seamless Reporting System"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage9}
                descClass="desc text-center"
                Description="Inventory Demand Planning"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage10}
                descClass="desc text-center"
                Description="Retail Accounting"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={aniImage11}
                descClass="desc text-center"
                Description="E-Commerce Analytics & BI"
              />
            </div>
          </div>
        </div>
      </div>
      {/* working-process-area-end */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default AccountsAndInventoryManagement;
