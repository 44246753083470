import React from "react";

const CardThree = (props) => {
  return (
    <div className="feature-table">
      <div className="row">
        <div className="feature-icon">
          <img src={props.itemImage} alt={props.description} />
        </div>
      </div>
      <p className="table-period">{props.description}</p>
    </div>
  );
};

export default CardThree;
