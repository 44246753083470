import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";

// About Image
import aboutImg1 from "../../assets/img/product/custom/5.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

// Modules Image
import benefitImg1 from "../../assets/img/icon/heart.png";
import benefitImg2 from "../../assets/img/icon/portfolio.png";
import benefitImg3 from "../../assets/img/icon/tips.png";
import benefitImg4 from "../../assets/img/icon/w-1.png";
import benefitImg5 from "../../assets/img/icon/w-2.png";
import benefitImg6 from "../../assets/img/icon/w-3.png";
import benefitImg7 from "../../assets/img/icon/why.png";
import benefitImg8 from "../../assets/img/icon/c-1.png";
import benefitImg9 from "../../assets/img/icon/c-2.png";
import benefitImg10 from "../../assets/img/icon/c-3.png";
import benefitImg11 from "../../assets/img/icon/c-4.png";

const HospitalManagementSystem = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="product"
        secondParentMenu="products"
        activeMenu="/hospital-management-system"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Hospital Management System"
        pageCategory="Products"
        pageName="Hospital Management System"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-40 md-pl-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="Hospital Mangement System"
                titleClass="title"
                title="We help the doctors to make things simple"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="Steema&#39;s hospital management system is a complete healthcare ERP suite for small to large hospitals and healthcare services. With the development of this software, our aim is to provide a very simple but powerful interface with the patient through a computer, which can be most beneficial to all."
                secondDescClass="desc"
                secondDescription="Very few doctors are using computers as administrative managerial tools due to the non-availability of proper software. We not only develop software but understand your work & its requirements, We help our doctors to make things simple."
              />
              {/* Section Title End */}
              <div className="btn-part mt-46">
                <Link to="/contact" className="readon learn-more">
                  Contact Us Now
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* working-process-area-start */}
      <div className="rs-pricing gray-bg style2 pt-120 pb-112 md-pt-80 md-pb-72">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Modules"
            titleClass="title"
            title="List of featured modules"
          />
          <div className="row">
            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg1} alt="OPD Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>OPD Module: </b>
                      The complete cycle of an effective OPD from Registration,
                      Patient History, Patient Diagnosis, and Prescription, etc.
                      is stored and managed effectively.
                      <br />
                      The system leverages Health Insurance and TPA tracking
                      along with ICDX codes. With powerful reporting from
                      scanning and storing entire Patient demographics like
                      X-Ray, Ultra Sound, Pathology, and other diagnostic
                      reports, etc.
                    </p>
                    <ul className="dots alignLeft">
                      <li>OPD Appointments</li>
                      <li>OPD Registrations</li>
                      <li>OPD Charges</li>
                      <li>Medical Observation</li>
                      <li>Consultant-wise OPD</li>
                      <li>Case-wise/ Department wise OPD</li>
                      <li>Patient Information Confidentiality</li>
                      <li>Procedures and clinical services Requests</li>
                      <li>Drug Prescriptions</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg2} alt="IPD Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>IPD Module: </b>
                      IPD with built-in Ward Management and Nursing Station
                      Management, ICU. NICU availability, the IPD gives a
                      360-degree view of the entire admitted patient movement
                      from admission to discharge.
                    </p>
                    <ul className="dots alignLeft">
                      <li>Wards, Floor, Room & Bed Configuration</li>
                      <li>Bed Occupation Matrix</li>
                      <li>Admission and Bed Allocation</li>
                      <li>Payment Receipts</li>
                      <li>Medical Observation and Nursing Notes</li>
                      <li>Physician Order Entry</li>
                      <li>Laboratory & Radiology Investigations</li>
                      <li>Procedures and clinical services Requests</li>
                      <li>Patient Dues Report</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg3} alt="OT Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>OT Module: </b>
                      Operation theater module caters to the scheduling of
                      operation theaters, surgery team, patient tracking,
                      operation theater consumable management, accounting and
                      Operation theater roster and notes with Death and Birth
                      certificates.
                    </p>
                    <ul className="dots alignLeft">
                      <li>O.T. Reservation for scheduled surgery</li>
                      <li>
                        Team of Doctors/Assistants involved in the operation
                      </li>
                      <li>Consent Forms</li>
                      <li>
                        Single or Multiple Doctor/Assistants revenue sharing
                      </li>
                      <li>Consultant Instructions in O.T.</li>
                      <li>Birth / Death Certificates</li>
                      <li>
                        Bill Charges Posting (Surgery and Package Surgeries)
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg4} alt="TPA Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>TPA Module: </b>
                      TPA covers seamless insurance management to process the
                      bills settlement of the patients covered under insurance.
                      <br />
                      It facilitates the verification of the eligibility of the
                      patient for reimbursement purposes. Tracking the status of
                      the claims becomes hassle free with our application.
                    </p>
                    <ul className="dots alignLeft">
                      <li>Health Insurance Sponsor Details</li>
                      <li>Sponsor Verification</li>
                      <li>Sponsor Invoicing</li>
                      <li>Financial Posting to Financial Accounting Module</li>
                      <li>Organization-wise Charges</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg5} alt="Radiology Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>Radiology Module: </b>
                      Designed to integrate medical imaging seamlessly into the
                      complete clinical workflow like Registration, Billing,
                      Test report Entry, Labels, and interface with Machines and
                      covers many Reports like Radiology Advance Slip Printing,
                      X-Ray reports, Ultrasound reports, CT Scan Reports,
                      Radiology Test Report Printing, worksheet printing, report
                      output to PDF, test report on the website, X-ray Film
                      Stocks, etc.
                    </p>
                    <ul className="dots alignLeft">
                      <li>Registration</li>
                      <li>Configuration of Services Offered</li>
                      <li>
                        Receive Investigation Requests from Outpatient &
                        Inpatient Modules
                      </li>
                      <li>Investigation Sample Collection</li>
                      <li>Integration with Patient Billing Module</li>
                      <li>Generate Investigation Reports</li>
                      <li>Consultant-wise Test Report</li>
                      <li>Department-wise Test Analysis</li>
                      <li>Doctor Referral</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg6} alt="Pathology Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>Pathology Module: </b>
                      Pathology automates the entire laboratory life cycle from
                      billing, daily sample collection monitoring, pathological
                      tests carried out, Test Report generation, patient
                      information management, diagnostic reports entry, fees
                      collection, bill printing, report printing, report
                      delivery, user access monitoring, Total Earning report,
                      cash collection report and many other MIS Reports.
                    </p>
                    <ul className="dots alignLeft">
                      <li>
                        Receive Investigation Requests from Outpatient &
                        Inpatient Department
                      </li>
                      <li>Patient Registration and receipts Printing</li>
                      <li>Investigation Sample Collection</li>
                      <li>Patient & Tests Sticker Printing</li>
                      <li>Integration with Patient Billing Module</li>
                      <li>Generate Investigation Reports</li>
                      <li>Warning & Alerts for Abnormal values</li>
                      <li>Consultant-wise Test Report</li>
                      <li>Department-wise Test Analysis</li>
                      <li>Reports Output to PDF</li>
                      <li>
                        Reports validation before final printing for delivery to
                        patients
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg7} alt="Inventory Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>Inventory Module: </b>
                      The Inventory Module covers complete stock movement across
                      the entire hospital. Indent Generation, Quotation,
                      Purchase order Purchase Register & Summary, Issue to the
                      department, Pending Purchase Order, Material Receipts, and
                      Purchase Bill.
                    </p>
                    <ul className="dots alignLeft">
                      <li>Purchase Indents & Approval</li>
                      <li>Quotation & Follow-ups</li>
                      <li>PO Generation</li>
                      <li>Goods Receipts</li>
                      <li>Purchase Invoicing & Returns</li>
                      <li>Goods Issues to Different departments</li>
                      <li>Stock Reorder Reports</li>
                      <li>Stock Ledgers and Stock Valuation</li>
                      <li>Supplier Outstanding</li>
                      <li>Financial Posting to Accounts Section</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img
                        src={benefitImg8}
                        alt="Pharmaceutical & Billing Module"
                      />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>Pharmaceutical & Billing Module: </b>
                      The pharmacy module deals with the automation of general
                      workflow and administration management process of pharmacy
                      from purchase to sale with Expiry report, Nearby Expiry,
                      Vat Report, Stock Re-order Report, Drug Stocks, Stock
                      Ledger & Stock Valuation and ABC Analysis, FSN Analysis,
                      Medicine Report, Pharmacy Stocks, Non-moving Medicine
                      Stocks, etc.
                    </p>
                    <ul className="dots alignLeft">
                      <li>Purchase Indents & Orders</li>
                      <li>Goods Receipt Notes/ Purchase Returns</li>
                      <li>Drugs/Medical Supplies to patients & Wards</li>
                      <li>Batch & Expiry Management</li>
                      <li>Fast-Moving/Non-Moving Drugs</li>
                      <li>Stock Transfer between Sub-Stores</li>
                      <li>Stock Reorder Reports</li>
                      <li>VAT Accounting</li>
                      <li>Drug Stocks (Company/Composition/category wise)</li>
                      <li>Stock Ledgers and Stock Valuation</li>
                      <li>ABC/FSN/XYZ/HML Sales Analysis</li>
                      <li>Supplier Outstanding</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg9} alt="Financial Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>Financial Module: </b>
                      Finance & Accounting Management privileges the Billing &
                      Accounting of the finances over the entire hospital
                      operation from Daily purchases/sales, Bank and Cash
                      Transactions; manages Payments to suppliers, Expenditures,
                      Receipts, Journals, Credit Notes, Debit Notes, TDS and
                      Balance Sheets, also the overall profit and loss details.
                    </p>
                    <ul className="dots alignLeft">
                      <li>Wards, Floor, Room & Bed Configuration</li>
                      <li>
                        Patient Indoor Admission, Bed Allocation & Transfers
                      </li>
                      <li>Payment Receipts (Cash/Credit/…)</li>
                      <li>
                        Bed Occupation Matrix (Floor wise/ Nursing Station wise
                        / room Category wise)
                      </li>
                      <li>Medical Observation and Nursing Notes</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg10} alt="Payroll Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>Payroll Module: </b>
                      Payroll and HR Management envelopes routine activities of
                      HRD and tasks pertaining to existing employees, attendance
                      and leaves, loans, ROTA registers new hires, and also
                      contractual employees.
                      <br />
                      The Payroll Software helps in filing and maintaining
                      various challans and returns under the various Statutory
                      Laws related to Salary like PF, ESI, Professional Tax,
                      TDS, etc.
                    </p>
                    <ul className="dots alignLeft">
                      <li>Employee Personal Details</li>
                      <li>Shift Defining</li>
                      <li>Duty Roaster Tracking</li>
                      <li>Attendance Management</li>
                      <li>Leaves/Holiday Accounting</li>
                      <li>Allowances & Deductions</li>
                      <li>Employee Promotion, Transfer & Resignation</li>
                      <li>Payroll Processing</li>
                      <li>Statutory Reports</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>

            <div className="col-lg-12 mb-30">
              {/* Module Section Start */}
              <div className="pricing-table">
                <div className="row">
                  <div className="col-md-2 col-xs-3">
                    <div className="pricing-icon mb-8">
                      <img src={benefitImg11} alt="Blood Bank Module" />
                    </div>
                  </div>
                  <div className="col-md-10 col-xs-9">
                    <p className="mb-5 alignLeft">
                      <b>Blood Bank Module: </b>
                      Blood Bank, the value-added module available in this
                      application facilitates Group wise Donor information,
                      Donor Card Generation, Donor Certificate, Group wise
                      Stock, Anti sera quality checks, Group wise Receipts, Test
                      Analysis, and Donor List with Biometric Interface, etc.
                    </p>
                    <ul className="dots alignLeft">
                      <li>Group-wise Donor Information</li>
                      <li>Donor Card Generation</li>
                      <li>Group-wise Receipts</li>
                      <li>Record Blood Transfusions</li>
                      <li>Group-wise Stocks</li>
                      <li>Test Count Registers</li>
                      <li>Camp Organization & Details</li>
                      <li>Daily Quality Check Updating</li>
                      <li>Expiry Tracking</li>
                      <li>Test Analysis</li>
                      <li>Donors List</li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Module Section End */}
            </div>
          </div>
        </div>
      </div>
      {/* working-process-area-end */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default HospitalManagementSystem;
