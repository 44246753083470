import SectionTitle from "../Common/SectionTitle";

import techImg1 from "../../assets/img/technology/cloud/azure.jpg";
import techImg2 from "../../assets/img/technology/cloud/aws.jpg";

const CloudTechnology = (props) => {
  return (
    <div className="rs-technology pt-120 pb-130 md-pt-70 md-pb-80">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title2 text-center mb-44"
          subtitleClass="sub-text gold-color"
          subtitle="Cloud Technology Index"
          titleClass="title title2"
          title="Providing Assistance On Cloud Services"
        />
        <div className="row">
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <a href={"/#"}>
                <div className="logo-img mb-20">
                  <img src={techImg1} alt="" />
                </div>
              </a>
          </div>
          <div className="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
              <a href={"/#"}>
                <div className="logo-img mb-20">
                  <img src={techImg2} alt="" />
                </div>
              </a>
          </div>
          <div className="col-lg-12 text-center">
            <div className="btn-part mt-30">
              <a className="readon started" href={"/contact"}>
                Request For Demo
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CloudTechnology;
