import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CloudTechnology from "../../components/Technology/CloudTechnology";

// About Image
import aboutImg from "../../assets/img/about/about-6.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";
import effectImg1 from "../../assets/img/about/dotted-3.png";
import effectImg2 from "../../assets/img/about/shape3.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const CloudSupport = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="support"
        secondParentMenu="supports"
        activeMenu="/cloud-support"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Cloud Support / Manage Cloud"
        pageCategory="Products"
        pageName="Azure Support / AWS Support"
      />
      {/* breadcrumb-area-end */}

      {/* about-area-start */}
      <div
        id="rs-about"
        className="rs-about gray-bg pt-120 pb-120 md-pt-80 md-pb-80"
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 md-mb-30">
              <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-14">
              <div className="contact-wrap">
                {/* Section Title Start */}
                <SectionTitle
                  sectionClass="sec-title mb-10"
                  subtitleClass="sub-text style-bg"
                  subtitle="Microsoft Azure | Amazon Web Service"
                  titleClass="title mt-5 pb-36"
                  title="The world is changing fast, make your move now."
                  descClass="desc pb-34"
                  description="Cloud has become an integral part of the IT strategy for any company. With more companies starting to provide “Software as a service” or SaaS, the cloud has become essential in business value and strategy."
                  secondDescClass="margin-0 pb-16"
                  secondDescription="There are so many ways that a company could use the cloud and it simply plays the major role in all important decisions regarding communication, brand building and reducing costs, and increasing customer acquisition."
                />
                <SectionTitle
                  sectionClass="sec-title mb-10"
                  secondDescClass="margin-0 pb-16"
                  secondDescription="Our cloud technical support services include consultancy, research, development, design and implementation, engineering, and operational support to ensure the success of your Cloud projects. You will be supported by our in-house experts who will complement your existing expertise with our in-depth knowledge of technology to ensure the successful delivery of your requirements at a reasonable cost."
                />
                {/* Section Title End */}
                <div className="btn-part">
                  <Link className="readon learn-more" to="/contact">
                    Request for Demo
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="shape-image">
            <img className="top dance" src={effectImg1} alt="" />
            <img className="bottom dance" src={effectImg2} alt="" />
          </div>
        </div>
        <div className="rs-services style5 pt-120 md-pt-80">
          <div className="container">
            <div className="row mb-30">
              <div className="col-lg-6 md-mb-30">
                <p>
                  <b>Features</b>
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Wide range of technical expertise available from Subject
                  Matter Experts
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Enterprise architecture, technical architecture, data
                  architecture, and security architecture expertise
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Expertise in the integration of Cloud applications with
                  traditional services
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Expertise across the virtualization technologies
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Expertise in IaaS, PaaS and SaaS models.
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Expertise in security (including accreditation expertise)
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Expertise in Service Oriented Architecture (SoA) based
                  services.
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Expertise in web services.
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Applicable to private, community, public, and hybrid Cloud
                  models
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Can be tailored for Discovery, Alpha, Beta, and Live Phases
                </p>
              </div>
              <div className="col-lg-6 md-mb-30">
                <p>
                  <b>Benefits</b>
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Reduce your risk by using experienced technical experts
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Enable you to understand the technical dependencies between
                  different services
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Deliver your benefits at a defined cost
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Benefit from our experience of delivery of SaaS, Iaas, PaaS
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Demonstrate compliance with the Digital Service Standard
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Benefit from our experience of the support of virtualized
                  services
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Benefit from our experience of the delivery of SOA
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Benefit from our experience of the support of web services
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Rapid, flexible and experienced resource management
                  <br />
                  <i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>
                  Sectors: defense, education, health, justice, local authority,
                  police, transport
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* about-area-end */}

      <CloudTechnology />

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default CloudSupport;
