import SectionTitle from '../../components/Common/SectionTitle';
import SingleProjectThree from '../../components/Project/SingleProjectThree';

import projectImg1 from '../../assets/img/project/style7/1.jpg';
import projectImg2 from '../../assets/img/project/style7/2.jpg';
import projectImg3 from '../../assets/img/project/style7/3.jpg';
import projectImg4 from '../../assets/img/project/style7/4.jpg';
import projectImg5 from '../../assets/img/project/style7/5.jpg';
import projectImg6 from '../../assets/img/project/style7/6.jpg';

const Project = () => {
    return (
        <div id="rs-portfolio" className="rs-project style4 pt-80 pb-60 md-pt-70 md-pb-50">
            <div className="container">
                <SectionTitle
                    sectionClass="sec-title2 text-center mb-44"
                    subtitleClass="sub-text"
                    subtitle="Products"
                    titleClass="title title2"
                    title="Our Frequently Running Products Available into Market"
                />
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleProjectThree
                            itemClass="project-item"
                            projectImage={projectImg1}
                            Title="School Management System"
                            Category="SaaS based education ERP Solution"
                            projectURL="/product/school-management-system"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleProjectThree
                            itemClass="project-item"
                            projectImage={projectImg2}
                            Title="Accounts &amp; Inventory Management"
                            Category="SaaS based Billing Software (Online / Offline)"
                            projectURL="/product/accounts-and-inventory-management"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleProjectThree
                            itemClass="project-item"
                            projectImage={projectImg3}
                            Title="Restaurant Management Software"
                            Category="Manage Restaurant (KOT) with our SaaS Product"
                            projectURL="/product/restaurant-management-system"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleProjectThree
                            itemClass="project-item"
                            projectImage={projectImg4}
                            Title="Hotel Management Solution"
                            Category="Manage Room Reservation, SaaS based ERP"
                            projectURL="/product/hotel-management-system"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleProjectThree
                            itemClass="project-item"
                            projectImage={projectImg5}
                            Title="Hospital Management System"
                            Category="Manage OPD/EPD &amp; Labs, Saas based ERP"
                            projectURL="/product/hospital-management-system"
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-30">
                        <SingleProjectThree
                            itemClass="project-item"
                            projectImage={projectImg6}
                            Title="Customer Relationship Management"
                            Category="SaaS based CRM Software"
                            projectURL="/product/customer-relationship-management"
                        />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Project