import React from "react";
import { Link } from "react-router-dom";

const FooterBottom = () => {
  return (
    <div className="footer-bottom">
      <div className="container">
        <div className="row y-middle">
          <div className="col-lg-7 text-right md-text-center md-mb-10 order-last">
            <ul className="copy-right-menu">
              <li>
                <Link to="/">Home</Link>
              </li>
              <li>
                <Link to="/about">About</Link>
              </li>
              <li>
                <Link to="/industry">Industries</Link>
              </li>
              <li>
                <Link to="/support/project-support">IT Remote Support</Link>
              </li>
              <li>
                <Link to="/faq">FAQs</Link>
              </li>
              <li>
                <Link to="/faq">Career</Link>
              </li>
            </ul>
          </div>
          <div className="col-lg-5">
            <div className="copyright md-text-center md-mb-10">
              <p>
                © 2017-24 All Rights Reserved.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FooterBottom;
