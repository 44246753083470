import SectionTitle from "../../components/Common/SectionTitle";
import SingleProjectTwo from '../../components/Project/SingleProjectTwo';

import projectImg1 from '../../assets/img/industry/1.png';
import projectImg2 from '../../assets/img/industry/2.png';
import projectImg3 from '../../assets/img/industry/3.png';
import projectImg4 from '../../assets/img/industry/4.png';
import projectImg5 from '../../assets/img/industry/5.png';
import projectImg6 from '../../assets/img/industry/6.png';
import projectImg7 from '../../assets/img/industry/7.png';
import projectImg8 from '../../assets/img/industry/8.png';
import projectImg9 from '../../assets/img/industry/9.png';
import projectImg10 from '../../assets/img/industry/10.png';
import projectImg11 from '../../assets/img/industry/11.png';
import projectImg12 from '../../assets/img/industry/12.png';
import projectImg13 from '../../assets/img/industry/13.png';
import projectImg14 from '../../assets/img/industry/14.png';

const WorkingIndustry = (props) => {
  return (
    <div id="rs-portfolio" className="rs-project style2 pt-80 pb-60 md-pt-70 md-pb-50">
      <div className="container">
        <SectionTitle
          sectionClass="sec-title text-center mb-10"
          subtitleClass="sub-text gold-color"
          subtitle="Serving Industries"
          titleClass="title pb-26"
          title="Industries we are working with"
          descClass="desc pb-34"
          description="Covering all major industries to offer you an optimum solution as per specified requirements. Our teams have years of experience in industry-specific skills."
        />
        <div className="row">
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg1}
              Title="Healthcare"
              Category="Healthcare applications development Services."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg2}
              Title="FinTech"
              Category="Banking &amp; finance software development."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg3}
              Title="Retail &amp; eCommerce"
              Category="Retail &amp; eCommerce software development."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg4}
              Title="Government &amp; Private Sector"
              Category="Software solution for Government &amp; Public Sector."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg5}
              Title="Travel &amp; Hospitality"
              Category="Travel &amp; Tourism software development Solution."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg6}
              Title="Education &amp; e-Learning"
              Category="Advance eLearning &amp; education software solution."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg7}
              Title="Logistics &amp; Transportation"
              Category="Logistics and transportation Software solution."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg8}
              Title="Real Estate &amp; Construction"
              Category="Real Estate &amp; Construction management software."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg9}
              Title="Energy &amp; Utilities"
              Category="Software solution for the energy & utility industry."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg10}
              Title="Insurance"
              Category="Insurance software development solution."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg11}
              Title="Diamond Industry"
              Category="Advance software solution for the diamond industry."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg12}
              Title="Manufacturing"
              Category="Software solutions for Manufacturing companies."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg13}
              Title="Media and Entertainment"
              Category="Technology support for the media and entertainment industry."
            />
          </div>
          <div className="col-lg-4 col-md-6 mb-30">
            <SingleProjectTwo
              itemClass="project-item"
              projectImage={projectImg14}
              Title="Social Business"
              Category="Support small businesses in the establishment of their own brand."
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WorkingIndustry;
