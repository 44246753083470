import React from "react";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";
import SingleProcess from "../../components/Process/SingleProcess";

// About Image
import aboutImg1 from "../../assets/img/product/custom/1.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Working Process Images
import smsImage1 from "../../assets/img/product/sms/1.jpg";
import smsImage2 from "../../assets/img/product/sms/2.jpg";
import smsImage3 from "../../assets/img/product/sms/3.jpg";
import smsImage4 from "../../assets/img/product/sms/4.jpg";
import smsImage5 from "../../assets/img/product/sms/5.jpg";
import smsImage6 from "../../assets/img/product/sms/6.jpg";
import smsImage7 from "../../assets/img/product/sms/7.jpg";
import smsImage8 from "../../assets/img/product/sms/8.jpg";
import smsImage9 from "../../assets/img/product/sms/9.jpg";
import smsImage10 from "../../assets/img/product/sms/10.jpg";
import smsImage11 from "../../assets/img/product/sms/11.jpg";
import smsImage12 from "../../assets/img/product/sms/12.jpg";
import smsImage13 from "../../assets/img/product/sms/13.jpg";
import smsImage14 from "../../assets/img/product/sms/14.jpg";
import smsImage15 from "../../assets/img/product/sms/15.jpg";
import smsImage16 from "../../assets/img/product/sms/16.jpg";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const SchoolManagementSystem = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="product"
        secondParentMenu="products"
        activeMenu="/school-management-system"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageCategory="Products"
        pageName="School Management System"
        pageTitle="School Management System"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-40 md-pl-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="School Mangement System"
                titleClass="title"
                title="Every day, we help schools to build, to nurture &amp; to succeed"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="The management of schools is an important component of any educational system. School authorities all over the world are continuously engaged in numerous activities to efficiently manage school functions and provide a better educational experience to students. However, managing school affairs is not at all easy in this fast-growing world."
                secondDescClass="desc"
                secondDescription="School management software helps schools better manage their operations and keep parents informed of their child's progress and safety. This software boasts a variety of features that improve the overall administration of schools."
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                secondDescClass="desc"
                secondDescription="Education institutions should now consider performing all activities in the cloud. Having a cloud-based Student Information System with no local on-premise footprint of server or software means less time and money spent on IT and more on broader education priorities. Now, let’s have a look at the characteristics of our highly effective school management software."
              />
              <SectionTitle
                sectionClass="sec-title4"
                secondDescClass="desc"
                secondDescription="It is important to us that we develop technology so that institutions can help each other in their own unique ways. Our unrivaled products and unparalleled services have stood the test of time and come out ahead. Using our solutions-centric and flexible approach, we enable customers to realize a vision tailored to their needs."
              />
              {/* Section Title End */}
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* working-process-area-start */}
      <div className="rs-process gray-bg style2 pt-120 pb-112 md-pt-80 md-pb-72">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Modules"
            titleClass="title"
            title="List of featured modules"
          />
          <div className="row">
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage1}
                descClass="desc text-center"
                Description="Admin / Principal Login"
              />
            </div>
            <div className="col-lg-3 col-sm-6 sm-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage2}
                descClass="desc text-center"
                Description="Student Management System"
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage3}
                descClass="desc text-center"
                Description="HR / Payroll Management"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage4}
                descClass="desc text-center"
                Description="Attendance Management System"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage5}
                descClass="desc text-center"
                Description="Fees & Accounts Management"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage6}
                descClass="desc text-center"
                Description="Online/Offline Exam Management"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage7}
                descClass="desc text-center"
                Description="Report Card System"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage8}
                descClass="desc text-center"
                Description="Library Management"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage9}
                descClass="desc text-center"
                Description="Time-table Management"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage10}
                descClass="desc text-center"
                Description="Certificates Management"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage11}
                descClass="desc text-center"
                Description="Learning Management System"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage12}
                descClass="desc text-center"
                Description="Transport Management System"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage13}
                descClass="desc text-center"
                Description="Hostel Management System"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage14}
                descClass="desc text-center"
                Description="Inventory Management System"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage15}
                descClass="desc text-center"
                Description="Communication Management (SMS, E-Mail, Web, Mobile)"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="sec-title mb-20"
                processImage={smsImage16}
                descClass="desc text-center"
                Description="Dedicated Mobile App with School Logo (Android & Apple)"
              />
            </div>
          </div>
        </div>
      </div>
      {/* working-process-area-end */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default SchoolManagementSystem;
