import React from "react";
import { Link } from "react-router-dom";

const CardOne = (props) => {
  const { itemClass, badgeClass, buttonURL } = props;
  return (
    <div className={itemClass ? itemClass : "pricing-table"}>
      <div className={badgeClass ? badgeClass : "pricing-badge"}>
        {props.badge}
      </div>
      <div className="row">
        <div className="col-md-4 col-xs-4">
          <div className="pricing-icon">
            <img src={props.itemImage} alt={props.title} />
          </div>
        </div>
        <div className="col-md-8 col-xs-8">
          <h4 className="mb-5">{props.title}</h4>
          <p className="table-period">{props.description}</p>
          <div className="btn-part">
            <Link
              to={buttonURL ? buttonURL : "#"}
              className={props.buttonClass}
            >
              {props.buttonText}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CardOne;
