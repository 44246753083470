import React from "react";
import { Route, BrowserRouter as Router, Switch } from "react-router-dom";

//Custom Components
import Home from "../pages/home";
import About from "../pages/about";
import SoftwareDevelopment from "../pages/service/software-development";
import WebDevelopment from "../pages/service/web-development";
import MobileAppDevelopment from "../pages/service/mobile-app-development";
import APIIntegration from "../pages/service/api-integration";
import DigitalMarketing from "../pages/service/digital-marketing";
import ECommerceDevelopment from "../pages/service/e-commerce-development";
import SchoolManagementSystem from "../pages/product/school-management-system";
import AccountsAndInventoryManagement from "../pages/product/accounts-and-inventory-management";
import RestaurantManagementSystem from "../pages/product/restaurant-management-system";
import HotelManagementSystem from "../pages/product/hotel-management-system";
import HospitalManagementSystem from "../pages/product/hospital-management-system";
import CustomerRelationshipManagement from "../pages/product/customer-relationship-management";
import JobSupport from "../pages/support/job-support";
import ProjectSupport from "../pages/support/project-support";
import CloudSupport from "../pages/support/cloud-support";
import Industry from "../pages/industry";
import Faq from "../pages/faq";
import Contact from "../pages/contact";
import Error from "../pages/404";
import LoadTop from "../components/Common/ScrollTop/LoadTop";

const App = () => {
  return (
    <div className="App">
      <Router>
        <LoadTop />
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/home" component={Home} />
          <Route path="/about" component={About} />
          <Route path="/service/software-development" component={SoftwareDevelopment} />
          <Route path="/service/web-development" component={WebDevelopment} />
          <Route path="/service/mobile-app-development" component={MobileAppDevelopment} />
          <Route path="/service/api-integration" component={APIIntegration} />
          <Route path="/service/digital-marketing" component={DigitalMarketing} />
          <Route path="/service/e-commerce-development" component={ECommerceDevelopment} />
          <Route path="/product/school-management-system" component={SchoolManagementSystem} />
          <Route path="/product/accounts-and-inventory-management" component={AccountsAndInventoryManagement} />
          <Route path="/product/restaurant-management-system" component={RestaurantManagementSystem} />
          <Route path="/product/hotel-management-system" component={HotelManagementSystem} />
          <Route path="/product/hospital-management-system" component={HospitalManagementSystem} />
          <Route path="/product/customer-relationship-management" component={CustomerRelationshipManagement} />
          <Route path="/support/job-support" component={JobSupport} />
          <Route path="/support/project-support" component={ProjectSupport} />
          <Route path="/support/cloud-support" component={CloudSupport} />
          <Route path="/industry" component={Industry} />
          <Route path="/faq" component={Faq} />
          <Route path="/contact" component={Contact} />
          <Route component={Error} />
        </Switch>
      </Router>
    </div>
  );
};
export default App;
