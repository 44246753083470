import React from "react";

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemPanel,
  AccordionItemButton,
} from "react-accessible-accordion";

import SectionTitle from "../../components/Common/SectionTitle";
import ContactForm from "../../components/Contact/ContactForm";

const FaqMain = () => {
  return (
    <React.Fragment>
      {/* Faq Part Start */}
      <div className="rs-faq gray-bg pt-120 md-pb-394 pb-434 md-pt-90 md-pb-90">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <SectionTitle
                sectionClass="sec-title2 text-center mb-44"
                subtitleClass="sub-text style-bg"
                subtitle="Frequently Asked Questions"
              />
            </div>
            <div className="col-lg-6">
              <h4 className="colorSecondary pl-30">General Asked</h4>
              <Accordion className="accodion-style1" preExpanded={"a"}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Where Steema is based in?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We are headquartered in Bangalore, Karnataka, India. We
                      have more than 50 employees spread across the country.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="b">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is your core expertise and industry experience?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Web Design and Development, Mobile App Development, and
                      Digtal Marketing are our core competencies. Ten years ago,
                      we started working on providing tech solutions, and we
                      have developed hundreds of web solutions for clients
                      belonging to different industries.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="c">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What assurance can you give me that the idea that I share
                      with you even after signing the NDA will be secured?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Our willingness to sign the NDA shows that we care about
                      the security of your idea. We follow strict security
                      actions to invalidate the chances of data theft. Above
                      all, we have expert and skilled professionals who have a
                      reputation for integrity.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="d">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I be assured that after the project delivery the
                      source code would not be reused in any other projects?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Steema is in the industry of information technology for
                      more than 10 yrs. We have over 5000 clients from all
                      across the world and bag more than 2000 projects on
                      various technologies. Reuse of code or any such
                      malpractice is strictly prohibited in our company. Our
                      employees follow a code of ethics.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="e">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is your project development methodology?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Every project is unique and requires a fresh approach, but
                      most projects pass through the following project
                      development cycle:
                    </p>
                    <ul className="dots">
                      <li>Step 1: Requirement Gathering and Analysis</li>
                      <li>Step 2: Consulting for the Right Solution</li>
                      <li>Step 3: Wireframing and Designing</li>
                      <li>Step 4: Development</li>
                      <li>Step 5: Testing & QA</li>
                      <li>Step 6: Deployment</li>
                      <li>Step 7: Support</li>
                    </ul>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="f">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you have Service Level Agreement in place?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>Yes, we do have.</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>

              <h4 className="mt-80 colorSecondary pl-30">Digital Marketing</h4>
              <Accordion className="accodion-style1" preExpanded={"a"}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is digital marketing?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Digital marketing involves marketing to people using
                      Internet-connected electronic devices, mainly computers,
                      smartphones and tablets. Digital marketing focuses on
                      channels such as search engines, social media, email,
                      websites and apps to connect with prospects and customers.
                      We provide all above mentioned servies.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="b">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Would my business benefit from digital marketing?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Definitely. Though companies in many business categories
                      continue to approach digital marketing with skepticism,
                      avoiding digital marketing denies your business access to
                      the media the majority of consumers turn to first and at
                      all hours of the day.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="c">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What&#39;s the biggest mistake in digital marketers make?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, of course, the primary goal of marketing is to create
                      leads. However, in the age of digital media a
                      brand-centric, sales approach deflects interest in your
                      company. Make your marketing customer-centric. Second
                      biggest mistake: Far too often, digital marketers start
                      and stop. We clearly communicate with client that to get a
                      better result, you need tocontinue the work about 6-8
                      month.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="d">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How often you will update my website?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We continuously make changes in the website because
                      websites that go for long periods with no updates and new
                      content are unlikely to perform well or support any useful
                      digital marketing objectives.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="e">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What do I need to know about mobile?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Mobile phones are the most used technology in the world.
                      Most of your prospects spend the majority of their media
                      time—and hours—using a smartphone. Mobile marketing
                      focuses on mobile strategies like messaging, mobile
                      applications, and mobile websites. A brand that ignores
                      mobile marketing is doomed.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="f">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is content marketing?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Content marketing is the creation and distribution of
                      educational and/or entertaining information assets for the
                      purpose of generating brand awareness, traffic, leads and
                      sales. Marketing content is generally free and does not
                      explicitly promote your brand as an ad would.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="g">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What&#39;s involved in creating a content marketing strategy?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      A content marketing strategy is a plan for building an
                      audience. Elements of content marketing strategy include
                      establishing objectives, audience personas, a value
                      proposition, content marketing mission statement, a buyer
                      journey map, and plans for creating, promoting and
                      analyzing how content marketing assets and programs
                      perform.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="h">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you also work on blogs?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      yes. Email, social media, search and other elements of
                      your digital marketing mix will depend on delivering
                      useful content. Though video outlets, podcasts, and media
                      galleries present other options, a blog is by far the most
                      used tactic for distributing marketing content on a
                      channel over which you have complete control.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="i">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What content you create?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      There can be no all-encompassing answer to this question
                      except to say we create content which buyers will find
                      relevant and useful. Leading candidates include blog
                      posts, articles, eBooks, info graphics, microsites,
                      videos, courses, case studies, newsletters, visuals and
                      various forms of interactive content.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="i">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How often do you publish blog posts or create new content?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      See, search engines will learn to index your site as often
                      as you publish content. However, neither your audience nor
                      search engines value garbage content. So, the answer to
                      this question is that we create content as often as we can
                      create great content, and we also consider the client
                      costs and ROI.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="j">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do people reach to my content?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Great headlines are the key to getting people to read,
                      watch or listen to your content. Over time, however, your
                      reputation becomes a factor too, so we build and honor it.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>

              <h4 className="mt-80 colorSecondary pl-30">Website Development</h4>
              <Accordion className="accodion-style1" preExpanded={"a"}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you offer a mobile device-compatible website?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Of course, we do! It&#39;s called Responsive Web Designing.
                      Our experts have hands-on the latest technology based on
                      HTML5 and CSS3. We develop eye-catching and graphically
                      convincing web designs that fit even on mobile devices.
                      Nevertheless, you may have to specify this in your
                      objectives before the project begins.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="b">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What design options do you provide? Do you provide
                      templates as options?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, we do provide a few options. However, we encourage
                      our buyers to be precise in their requirements and
                      elements on the website depending upon what, we develop a
                      few options. This saves time and money and fastens the
                      process of web development.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="c">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is your architecture to design or develop the
                      websites?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We use standard architecture – Model View Controller
                      (MVC), a type of UI separating the representation of
                      information from the user interaction. We do have
                      different frameworks and use as per the project type and
                      demand.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="d">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      If I have eCommerce to develop, what&#39;s your recommendation
                      and why?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      eCommerce requires special coding and huge flexibility. We
                      recommend closed or paid sources (such as .net core) over
                      open-source technology for several reasons, security and
                      flexibility being a major concern.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="e">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do you maintain websites?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We maintain websites by adding new content to the site,
                      adding new pages, adding or replacing images, or removing
                      them from the site or anything that you want to be visible
                      on your website.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="f">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What type of assistance do you give us?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      You can get support from us through e-mail, over the
                      telephone, and also via live chat.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
            <div className="col-lg-6">
              <h4 className="colorSecondary pl-30">Software Development</h4>
              <Accordion className="accodion-style1" preExpanded={"a"}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What can you help me with?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      With whatever we can. Preparing cost analysis,
                      establishing team roles, creating roadmaps. Defining
                      business goals, key business values, target users, main
                      features, prioritising. We can find you similar projects
                      that are already active on the market to benchmark
                      against. We can assist you with creating user personas,
                      mock-ups, user stories, time frame, establishing project
                      status and preparing project estimation. We'll be happy to
                      outline project specification, prepare wireframes, details
                      concerning integrations with external services, API
                      documentation, required features list.
                      <br />
                      <br />
                      In terms of project development we prepare server-side
                      architecture, front end, back end, graphic and UX design,
                      and create back-office consoles.
                      <br />
                      <br />
                      We are also happy to advise our customers in terms of
                      budgeting, scheduling, risk management, and business model
                      creation.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="b">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do you start to work on my idea?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The process begins when you contact us and tell us about
                      your requirements. We analyze your requirements and
                      respond to you. On the basis of further discussion, you
                      can choose an engagement model that suits you the best.
                      After that, we begin the process of development.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="c">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is the average completion time for a Web
                      Portal/Application?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      The time limit of any web assignment is normally dictated
                      by the client. If you have any time limit in mind we will
                      attempt to assemble it for you.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="d">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What are the payment steps and working process?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      For the project to begin, we require a 50% deposit, with
                      the remainder to be paid at agreed milestones.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="e">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How do you guarantee product quality?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We evaluate the result after every two weeks, we test our
                      work (we conduct both development and acceptance tests),
                      we present it to you, we apply your feedback so you know
                      you get what you are paying for.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="f">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Will I be having a full-time dedicated developer?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      {" "}
                      Yes, you will have your own dedicated developers, who will
                      work up to 180 hours in one month for your project. You
                      can utilize our ready-to-use IT infrastructure and
                      dedicated leased lines, which will ensure the elimination
                      of work interruption.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="g">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can I select my preferred developer?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      {" "}
                      Yes, you can select the developers you prefer. The company
                      provides resumes of selected programmers and sends them to
                      the client. The client then selects a few of them to work
                      on the project.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="h">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How many developers can I hire for my project?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, you can select the developers you prefer. The company
                      provides resumes of selected programmers and sends them to
                      the client. The client then selects a few of them to work
                      on the project.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="i">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      How can I be sure you work at my project as much as you
                      declare?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We thoroughly monitor our developers and control how much
                      time they spend on each task down to the exact minute. We
                      use Harvest software and Redmine to track time spent on
                      your project.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="j">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Are you able to double the size of the team involved in
                      the project if the need arises?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Whether you want to expand your product or create a
                      completely new one simultaneously, and then again focus
                      only on one of your projects, we are always ready to adapt
                      to your requirements. If the work is less intense team
                      size can be reduced. When improvements need to be
                      implemented fast, we can quickly assign additional team
                      members to speed up each phase of the development process.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="k">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Will development include the creation of prototypes and
                      demo versions that I can try out?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We would not call it exactly a demo version but using
                      Agile Methodolgy compels us to create operating software
                      as soon as possible and later just add next features along
                      the process. Therefore, you'll be able to examine and test
                      your software very early.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="l">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Will my observations concerning the prototypes be factored
                      into the final version?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Of course. Thanks to the Agile Methodology we are able to
                      iterate the project during its development and include
                      your feedback to improve the product as it is being
                      created.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="m">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can the software be upgraded in the future as new
                      technology becomes available?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Of course! We are always happy to improve, upgrade and
                      further develop our work.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="n">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What if I come to you with a product that I want to
                      improve?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      We&#39;ll be more than happy to help you with that. Beginning
                      with a UX Audit (if applicable) through improving your
                      system architecture and adding new features.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="o">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Will a project manager be assigned to me as a contact
                      person? Will this contact person be available throughout
                      the time the software is being developed?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes. All our customers are assigned a liaison that serves
                      as their guide and information source throughout our whole
                      cooperation.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="p">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What does your development cost?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      It depends on many things: project scope, time spent on
                      the project and technological issues. We know it sounds
                      vague but its simply honest. Remember that each software
                      project is different, especially if you had a particularly
                      original idea. We do not want to give you an unrealistic
                      estimate, instead we want to provide you with the best
                      quality possible within your budget.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>

              <h4 className="mt-80 colorSecondary pl-30">Mobile App Development</h4>
              <Accordion className="accodion-style1" preExpanded={"a"}>
                <AccordionItem uuid="a">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      What is the difference between a Native App and Web Based
                      App?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Native apps are built for specific platforms. They are
                      built using platforms&#39; native SDK and development
                      platforms and are able to use some of the specific
                      features of the device – e.g. Camera, Volume controller,
                      speaker, etc. The web-based apps (also web apps) run
                      within the mobile browser and are virtually webpages that
                      imitate the look of a native mobile UI.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="b">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you develop a mobile app for all platforms?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, we build mobile applications for all major mobile
                      development platforms. You can hire our developers for
                      developing apps for iOS (iPhone and iPad), Android,
                      BlackBerry, Symbian, and Windows Mobile.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="c">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you also upload the application to the app store?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, we do it free of cost! Nevertheless, you&#39;ll have to
                      pay the minimum amount of charge (fees) by the main
                      company.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="d">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Do you also do marketing of Mobile apps?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      No, The mobile apps which we develop for our clients, we
                      do not do marketing on that apps.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="e">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can you use the graphics / UI which I have created?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Yes, we can. You can hire us just to write codes for
                      programming.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="f">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Does your company guarantee that Apple won&#39;t reject the
                      app?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>
                      Most of the apps we develop don&#39;t get rejected. There are
                      no special criteria for this but just making a neat and
                      clean app works. Our apps are foolproof and bug-free as
                      they pass various robust testing. Though we don&#39;t
                      guarantee approval on iTunes as it may be rejected other
                      than a technical fault.
                    </p>
                  </AccordionItemPanel>
                </AccordionItem>

                <AccordionItem uuid="g">
                  <AccordionItemHeading>
                    <AccordionItemButton>
                      Can we update the content of the application after it&#39;s
                      built?
                    </AccordionItemButton>
                  </AccordionItemHeading>
                  <AccordionItemPanel>
                    <p>You may! Nevertheless, this may be chargeable.</p>
                  </AccordionItemPanel>
                </AccordionItem>
              </Accordion>
            </div>
          </div>
        </div>
      </div>
      {/* Faq Part End */}

      {/* Contact Part Start */}
      <div className="rs-contact style2 mt--314 pb-90">
        <div className="container">
          <div className="contact-box-wrap">
            <SectionTitle
              sectionClass="sec-title2 text-center mb-34"
              subtitleClass="sub-text style-bg con-bg white-color"
              subtitle="Contact"
              titleClass="title testi-title white-color"
              title="Have a question that's not answered here? Contact Steema today!"
            />
            <ContactForm />
          </div>
        </div>
      </div>
      {/* Contact Part End */}
    </React.Fragment>
  );
};

export default FaqMain;
