import React from "react";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";
import CardThree from "../../components/Card/CardThree";

// About Image
import aboutImg1 from "../../assets/img/product/custom/3.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Features Image
import kotImg1 from "../../assets/img/product/kot/1.png";
import kotImg2 from "../../assets/img/product/kot/2.png";
import kotImg3 from "../../assets/img/product/kot/3.png";
import kotImg4 from "../../assets/img/product/kot/4.png";
import kotImg5 from "../../assets/img/product/kot/5.png";
import kotImg6 from "../../assets/img/product/kot/6.png";
import kotImg7 from "../../assets/img/product/kot/7.png";
import kotImg8 from "../../assets/img/product/kot/8.png";
import kotImg9 from "../../assets/img/product/kot/9.png";
import kotImg10 from "../../assets/img/product/kot/10.png";
import kotImg11 from "../../assets/img/product/kot/11.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const RestaurantManagementSystem = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="product"
        secondParentMenu="products"
        activeMenu="/restaurant-management-system"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="Restaurant Management System"
        pageCategory="Products"
        pageName="Restaurant Management System"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about rs-pricing pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-40 md-pl-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="Restaurant Management System"
                titleClass="title"
                title="The restaurant business is incomplete without a comprehensive KOT"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="We offer a complete restaurant management system that does much more than a point-of-sale!"
                secondDescClass="desc"
                secondDescription="If you're a restaurateur hoping to grow your business, one of your main goals would be to serve your customers faster. The key to &#39;&#39;Serve Quick&#39;&#39; is made possible with our Cloud-based restaurant management system which is easy to use, and available on Android, iOS, and Browser."
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                secondDescClass="desc"
                secondDescription="We have designed this billing ERP keeping in mind that it will reduce your compliance burden so that you can focus on your business completely."
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                secondDescClass="desc"
                secondDescription="We also serve you a comprehensive KOT application which can be used to accomplish a wide range of tasks."
              />
              {/* Section Title End */}
            </div>
          </div>
        </div>
        <div className="rs-services style5 pt-60 md-pt-40">
          <div className="container mb-30">
            <div className="row">
              <div className="col-md-12">
                <p>
                  <b>Key Features of Steema Restaurant POS System</b>
                </p>
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={kotImg1}
                  description="RECIPE and MENU"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree itemImage={kotImg2} description="ORDER TAKING" />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree itemImage={kotImg3} description="MOBILE APP" />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree itemImage={kotImg4} description="CLOUD POS" />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree itemImage={kotImg5} description="SECURITY" />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={kotImg6}
                  description="TIP and CHECK"
                />
              </div>
            </div>
            <div className="row">
              <div className="col-md-1"></div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={kotImg7}
                  description="FOOD COSTING / WASTAGE REPORTS"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={kotImg8}
                  description="INVENTORY and PRODUCTION"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={kotImg9}
                  description="HANDLING CRM and PROMOTION"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree
                  itemImage={kotImg10}
                  description="RESTAURANT CHAIN"
                />
              </div>
              <div className="col-md-2 mb-10">
                <CardThree itemImage={kotImg11} description="INTEGRATIONS" />
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* Category Section Start */}
      <div className="rs-pricing gray-bg pt-120 pb-120 md-pt-80 md-pb-60">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Benefits"
            titleClass="title title2"
            title="Benefits of the KOT application"
          />
          <div className="row">
            <div className="col-lg-12">
              <ul>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Handling your online orders is now simpler and more efficient</li>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Deliver consistent taste that foodies enjoy by controlling the recipes</li>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Plan, prepare, and produce to maximize profit</li>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Make bills and account for tips with ease</li>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Delight the guests and earn their loyalty</li>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Accurate inventory reports help keep supplies fresh and control food cost</li>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Android, iPad, and Web the best ever Cloud POS for QSR</li>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>With complete and effective control for your store operations</li>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>To take Orders and deliver them with ease</li>
                <li><i className="flaticon-play-button-1">&nbsp;&nbsp;&nbsp;</i>Budgeting, Cash flow analysis, Ledger management, and much more</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      {/* Category Section End */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default RestaurantManagementSystem;
