import React from "react";

const SingleProcess = (props) => {
  if (props.Title !== "" && props.Title != null) {
    return (
      <div className={props.itemClass}>
        <div className="process-img">
          <img src={props.processImage} alt={props.Title} />
        </div>
        <div className="process-text">
          <h3 className={props.titleClass}>{props.Title}</h3>
          <p className={props.descClass}>{props.Description}</p>
        </div>
      </div>
    );
  } else {
    return (
      <div className={props.itemClass}>
        <div className="process-img">
          <img src={props.processImage} alt={props.Title} />
        </div>
        <p className={props.descClass}>{props.Description}</p>
      </div>
    );
  }
};

export default SingleProcess;
