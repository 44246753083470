import React from "react";
import { Link } from "react-router-dom";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";
import SingleProcess from "../../components/Process/SingleProcess";
import CardTwo from "../../components/Card/CardTwo";

// Working Process Images
import processImage1 from "../../assets/img/process/style2/1.jpg";
import processImage2 from "../../assets/img/process/style2/2.jpg";
import processImage3 from "../../assets/img/process/style2/3.jpg";
import processImage4 from "../../assets/img/process/style2/4.jpg";

// About Image
import aboutImg1 from "../../assets/img/service/custom/6.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Benefits Image
import benefitImg1 from "../../assets/img/icon/heart.png";
import benefitImg2 from "../../assets/img/icon/portfolio.png";
import benefitImg3 from "../../assets/img/icon/tips.png";
import benefitImg4 from "../../assets/img/icon/w-1.png";
import benefitImg5 from "../../assets/img/icon/w-2.png";
import benefitImg6 from "../../assets/img/icon/w-3.png";
import benefitImg7 from "../../assets/img/icon/why.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const ECommerceDevelopment = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/e-commerce-development"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="E-Commerce Development"
        pageCategory="Services"
        pageName="E-Commerce Development"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="E-Commerce Development"
                titleClass="title"
                title="We Help to Implement Your Ideas into Automation"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="Now that the world is getting more advanced in terms of technology. Consumer patterns and behaviors are changing at a rapid pace."
                secondDescClass="desc"
                secondDescription="If you want to reach these progressive consumers, you need to stay ahead of the competition by providing a professional, reachable, and secure environment such as an e-commerce application."
              />
              <SectionTitle
                sectionClass="sec-title4"
                secondDescClass="desc"
                secondDescription="At Steema, we provide e-commerce development services with the latest technologies that fulfill your every critical requirement. Along with our expert development services, we also deliver technical consultation alongside proper strategizing of project plans. Dedicated to providing you with top-notch e-commerce web design, we never leave any stone unturned."
              />
              {/* Section Title End */}
              <div className="btn-part mt-46">
                <Link to="/contact" className="readon learn-more">
                  Contact Us Now
                </Link>
              </div>
            </div>
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* working-process-area-start */}
      <div className="rs-process gray-bg style2 pt-120 pb-112 md-pt-80 md-pb-72">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Process"
            titleClass="title"
            title="Steps in e-commerce development"
          />
          <div className="row">
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="addon-process"
                processImage={processImage1}
                titleClass="title"
                Title="Domain Degistration"
              />
            </div>
            <div className="col-lg-3 col-sm-6 md-mb-50">
              <SingleProcess
                itemClass="addon-process"
                processImage={processImage2}
                titleClass="title"
                Title="Logo Design &amp; Brand Building"
              />
            </div>
            <div className="col-lg-3 col-sm-6 sm-mb-50">
              <SingleProcess
                itemClass="addon-process"
                processImage={processImage3}
                titleClass="title"
                Title="Website Design &amp; Development"
              />
            </div>
            <div className="col-lg-3 col-sm-6">
              <SingleProcess
                itemClass="addon-process"
                processImage={processImage4}
                titleClass="title"
                Title="Hosting / Deployment"
              />
            </div>
          </div>
        </div>
      </div>
      {/* working-process-area-end */}

      {/* why-choose-us-area-start */}
      <div className="rs-pricing style2 pt-120 pb-144 md-pt-80 md-pb-80">
        <div className="container">
          <SectionTitle
            sectionClass="sec-title2 text-center mb-46"
            subtitleClass="sub-text style-bg"
            subtitle="Why Choose Us?"
            titleClass="title title2"
            title="How our service is beneficial?"
          />
          <div className="row">
            <div className="col-lg-12 mb-30">
              <CardTwo
                itemClass="pricing-table new-style2"
                itemImage={benefitImg1}
                title="Let's start by talking"
                description="Steema Software has been providing all-around IT solutions since 2017. We help our clients achieve success by giving them innovative solutions to every difficult problem they face. We have worked with a wide range of clients, both public and private. Our impressive portfolio demonstrates our expertise in the industry."
              />
            </div>

            <div className="col-lg-12 mb-30">
              <CardTwo
                itemClass="pricing-table new-style2"
                itemImage={benefitImg2}
                title="Because we give big-time support"
                description="Our journey with you doesn&#39;t stop at the end of the project. We continue our support after the project is finished. We call it after-service support. Our relationship continues on and on and on till infinity. Please inform us if you encounter any problems when using any of our digital services/products, and we'll fix them immediately."
              />
            </div>

            <div className="col-lg-12 mb-30">
              <CardTwo
                itemClass="pricing-table new-style2"
                itemImage={benefitImg3}
                title="Because of our establishment"
                description="We are equipped with a modern office with the latest technology, and seated beside them is a team of highly skilled professionals. It will only take us a few minutes to solve any problem you have, no matter how difficult it might seem."
              />
            </div>

            <div className="col-lg-12 mb-30">
              <CardTwo
                itemClass="pricing-table new-style2"
                itemImage={benefitImg4}
                title="Because of our skilled manpower"
                description="Steema boasts manpower who are extremely talented and dedicated to their work. We have skilled human resources for every technical/non-technical work that lands on our canvas. Our employees are our strength. It is because of their problem-solving skills that we have succeeded."
              />
            </div>

            <div className="col-lg-12 mb-30">
              <CardTwo
                itemClass="pricing-table new-style2"
                itemImage={benefitImg5}
                title="Because of Integrated Infinity solutions"
                description="Be its IT solutions, business development, outsourcing, or strategizing your company’s growth. By contacting Steema, you get freedom from all digital-related problems. We show companies the right path. We always do the right thing, with pace, passion, and precision. Be it random glitches or a complete overhaul, all you have to do is contact or visit us."
              />
            </div>

            <div className="col-lg-12 mb-30">
              <CardTwo
                itemClass="pricing-table new-style2"
                itemImage={benefitImg6}
                title="Because of easy project execution"
                description="Many times, companies know beforehand what their goals are but run into problems while moving toward them. Steema helps them make the right decisions and execute them. We have a “how” to their every vision."
              />
            </div>

            <div className="col-lg-12 mb-30">
              <CardTwo
                itemClass="pricing-table new-style2"
                itemImage={benefitImg7}
                title="Because we give custom solutions"
                description="Steema is providing custom solutions to all types of clients. With our ability to offer creative solutions to every difficult problem, our clients can achieve success."
              />
            </div>
          </div>
        </div>
      </div>
      {/* why-choose-us-area-end */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section End */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default ECommerceDevelopment;
