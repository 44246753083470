import React from "react";

const CardTwo = (props) => {
  const { itemClass, badgeClass } = props;
  if (props.badge !== "" && props.badge != null) {
    return (
      <div className={itemClass ? itemClass : "pricing-table"}>
        <div className={badgeClass ? badgeClass : "pricing-badge"}>
          {props.badge}
        </div>
        <div className="row">
          <div className="col-md-2 col-xs-3">
            <div className="pricing-icon mb-8">
              <img src={props.itemImage} alt={props.title} />
            </div>
          </div>
          <div className="col-md-10 col-xs-9">
            <h4 className="mb-5">{props.title}</h4>
            <p className="mb-5">{props.description}</p>
          </div>
        </div>
      </div>
    );
  } else {
    return (
      <div className={itemClass ? itemClass : "pricing-table"}>
        <div className="row">
          <div className="col-md-2 col-xs-3">
            <div className="pricing-icon mb-8">
              <img src={props.itemImage} alt={props.title} />
            </div>
          </div>
          <div className="col-md-10 col-xs-9">
            <h4 className="mb-5">{props.title}</h4>
            <p className="mb-5">{props.description}</p>
          </div>
        </div>
      </div>
    );
  }
};

export default CardTwo;
