import React from "react";
import BannerHomeFive from "../../components/Banner/BannerHomeFive";
import Service from "./ServiceSection";
import About from "./AboutSection";
import WhyChooseUs from "./WhyChooseUsSection";
import WhyChooseUs2 from "./WhyChooseUsSection2";
import Testimonial from "./TestimonialSection";
import ProjectSection from "./ProjectSection";
//import Brand from "../../components/Common/Brand";
import ScrollToTop from "../../components/Common/ScrollTop";

const HomeFiveMain = () => {
  return (
    <React.Fragment>
      {/* banner-start */}
      <BannerHomeFive />
      {/* banner-start */}

      {/* About-area-start */}
      <About />
      {/* About-area-end */}

      {/* Service-area-start */}
      <Service />
      {/* Service-area-end */}

      {/* WhyChooseUs-area-start */}
      <WhyChooseUs />
      {/* WhyChooseUs-area-end */}

      {/* WhyChooseUs-area-start */}
      <WhyChooseUs2 />
      {/* WhyChooseUs-area-end */}

      {/* blog-area-start */}
      <ProjectSection />
      {/* blog-area-end */}

      {/* Testimonial-area-start */}
      <Testimonial />
      {/* Testimonial-area-end */}

      {/* brand-area-start */}
      {/* <Brand /> */}
      {/* brand-area-end */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}
    </React.Fragment>
  );
};

export default HomeFiveMain;
