import React from "react";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterHomeFive from "../../components/Elements/Counter/CounterHomeFive";
import SingleServiceTwo from "../../components/Service/SingleServiceTwo";

// Service Image Icons
import mainIcon1 from "../../assets/img/service/style3/main-img/1.png";
import hoverIcon1 from "../../assets/img/service/style3/hover-img/1.png";
import mainIcon2 from "../../assets/img/service/style3/main-img/2.png";
import hoverIcon2 from "../../assets/img/service/style3/hover-img/2.png";
import mainIcon3 from "../../assets/img/service/style3/main-img/3.png";
import hoverIcon3 from "../../assets/img/service/style3/hover-img/3.png";
import mainIcon4 from "../../assets/img/service/style3/main-img/4.png";
import hoverIcon4 from "../../assets/img/service/style3/hover-img/4.png";

const About = () => {
  return (
    <React.Fragment>
      <div id="rs-about" className="rs-about pt-60 md-pt-70 pb-40">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-5">
              <SectionTitle
                sectionClass="sec-title mb-50"
                subtitleClass="sub-text style4-bg"
                subtitle="About Us"
                titleClass="title pb-20"
                title="Over 10 Years Helping Brands to Reach Full Potential"
                descClass="desc"
                description="Over 10 years working in IT services developing software applications and mobile apps for clients all over the world. As a company, we strive to make your life as easy as possible with our products. Our goal is to help our customers by planning, developing, and delivering the best possible solutions."
              />
              {/* counter area start */}
              <CounterHomeFive />
              {/* counter area end */}
            </div>
            <div className="col-lg-7">
              <div className="rs-services style3 md-pt-50">
                <div className="row">
                  <div className="col-md-6 pr-10 pt-40 sm-pt-0 sm-pr-0 sm-pl-0">
                    <SingleServiceTwo
                      itemClass="services-item mb-20"
                      mainIcon={mainIcon1}
                      hoverIcon={hoverIcon1}
                      Title="High-quality Code"
                      Text="Code that is clear, simple, well-tested, bug-free, refactored, documented, and performant."
                    />
                    <SingleServiceTwo
                      itemClass="services-item cyan-bg"
                      mainIcon={mainIcon2}
                      hoverIcon={hoverIcon2}
                      Title="Full-stack teams"
                      Text="Our team members collectively have all the skills needed to solve the problems."
                    />
                  </div>
                  <div className="col-md-6 pl-10 sm-pr-0 sm-pl-0 sm-mt-20">
                    <SingleServiceTwo
                      itemClass="services-item gold-bg mb-20"
                      mainIcon={mainIcon3}
                      hoverIcon={hoverIcon3}
                      Title="Agile Approach"
                      Text="Helps us to deliver value to our customers faster and with fewer headaches."
                    />
                    <SingleServiceTwo
                      itemClass="services-item blue-bg"
                      mainIcon={mainIcon4}
                      hoverIcon={hoverIcon4}
                      Title="Data Reliability &amp; Security"
                      Text="Identify the unreliable data, fix the issue with the reliable data, and confirm that the system is 100% safe."
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default About;
