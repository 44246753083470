import { Link } from 'react-router-dom';
import SectionTitle from '../../components/Common/SectionTitle';
import SingleServiceTwo from '../../components/Service/SingleServiceTwo';

// Service Image Icons
import mainIcon1 from '../../assets/img/service/style2/main-img/1.png';
import hoverIcon1 from '../../assets/img/service/style2/hover-img/1.png';
import mainIcon2 from '../../assets/img/service/style2/main-img/2.png';
import hoverIcon2 from '../../assets/img/service/style2/hover-img/2.png';
import mainIcon3 from '../../assets/img/service/style2/main-img/3.png';
import hoverIcon3 from '../../assets/img/service/style2/hover-img/3.png';
import mainIcon4 from '../../assets/img/service/style2/main-img/4.png';
import hoverIcon4 from '../../assets/img/service/style2/hover-img/4.png';
import mainIcon5 from '../../assets/img/service/style2/main-img/5.png';
import hoverIcon5 from '../../assets/img/service/style2/hover-img/5.png';
import mainIcon6 from '../../assets/img/service/style2/main-img/6.png';
import hoverIcon6 from '../../assets/img/service/style2/hover-img/6.png';

const Service = () => {
    return (
        <div id="rs-service" className="rs-services style3 rs-rain-animate gray-bg pt-120 pb-100 md-pt-70 md-pb-60">
            <div className="container">
                <div className="row md-mb-60">
                    <div className="col-lg-6 mb-60 md-mb-20">
                        <SectionTitle
                            sectionClass="sec-title2"
                            subtitleClass="sub-text"
                            subtitle="Services"
                            titleClass="title testi-title"
                            title="What Solutions We Provide to Our Valued Customers"
                        />
                    </div>
                    <div className="col-lg-6 mb-60 md-mb-0">
                        <div className="btn-part text-right mt-60 md-mt-0 md-text-left">
                            <Link className="readon started" to="/service/software-development">Get Started</Link>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item"
                            prefix="01"
                            mainIcon={mainIcon1}
                            hoverIcon={hoverIcon1}
                            Title="Software Development"
                            serviceURL="/service/software-development"
                            Text="Providing you with powerful and adaptable digital solutions that satisfy your needs today and open up opportunities tomorrow, we help you in transforming and frequently running your business."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item pink-bg"
                            prefix="02"
                            mainIcon={mainIcon2}
                            hoverIcon={hoverIcon2}
                            Title="Web Development"
                            serviceURL="/service/web-development"
                            Text="We build dynamic experiences and a wide variety of custom web development solutions. Over 10  years of experience makes us an excellent and feasible Web Development Company."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item aqua-bg"
                            prefix="03"
                            mainIcon={mainIcon3}
                            hoverIcon={hoverIcon3}
                            Title="Mobile App Development"
                            serviceURL="/service/mobile-app-development"
                            Text="Increased usage of the mobile app has created a demand for a customer-centered mobile apps that elevates your business to profit and usability. We build an interactive and adaptable mobile apps."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item paste-bg"
                            prefix="04"
                            mainIcon={mainIcon4}
                            hoverIcon={hoverIcon4}
                            Title="API Integration"
                            serviceURL="/service/api-integration"
                            Text="Our system integration services will ensure that your web app or website is flawlessly integrated with the standard or custom APIs."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item purple-bg"
                            prefix="05"
                            mainIcon={mainIcon5}
                            hoverIcon={hoverIcon5}
                            Title="Digital Marketing"
                            serviceURL="/service/digital-marketing"
                            Text="Our performance-driven digital marketing services help your company accomplish its toughest goals, from improving conversions to growing brand awareness."
                        />
                    </div>
                    <div className="col-lg-4 col-md-6 mb-20">
                        <SingleServiceTwo
                            itemClass="services-item green-bg"
                            prefix="06"
                            mainIcon={mainIcon6}
                            hoverIcon={hoverIcon6}
                            Title="E-Commerce Development"
                            serviceURL="/service/e-commerce-development"
                            Text="If you want to reach today's progressive consumers, you need to stay ahead of the competition by providing consumers a professional and secure environment."
                        />
                    </div>
                </div>
            </div>
            <div className="line-inner">
                <div className="line"></div>
                <div className="line"></div>
                <div className="line"></div>
            </div>
        </div>
    );
}

export default Service;