import React from "react";
import Header from "../../components/Layout/Header/HeaderStyleFive";
import Footer from "../../components/Layout/Footer";
import SearchModal from "../../components/Layout/Header/SearchModal";
import SiteBreadcrumb from "../../components/Common/Breadcumb";
import SectionTitle from "../../components/Common/SectionTitle";
import CounterStyleThree from "../../components/Elements/Counter/CounterStyleThree";
import ScrollToTop from "../../components/Common/ScrollTop";

// About Image
import aboutImg1 from "../../assets/img/service/custom/4.png";
import danceEffectImg from "../../assets/img/about/effect-1.png";

// Features Images
import mainImg from "../../assets/img/choose/2.png";
import animImg1 from "../../assets/img/choose/dotted-1.png";
import animImg2 from "../../assets/img/choose/dotted-2.png";
import iconOne from "../../assets/img/choose/icons/1.png";
import iconTwo from "../../assets/img/choose/icons/2.png";
import iconFour from "../../assets/img/choose/icons/4.png";

// Platform Images
import brandLogo1 from "../../assets/img/technology/apiintegration/1.png";
import brandLogo2 from "../../assets/img/technology/apiintegration/2.png";
import brandLogo3 from "../../assets/img/technology/apiintegration/3.png";
import brandLogo4 from "../../assets/img/technology/apiintegration/4.png";
import brandLogo5 from "../../assets/img/technology/apiintegration/5.png";
import brandLogo6 from "../../assets/img/technology/apiintegration/6.png";

// Breadcrumbs Image
import breadcrumbsImg from "../../assets/img/breadcrumbs/1.jpg";

const APIIntegration = () => {
  return (
    <React.Fragment>
      <Header
        parentMenu="service"
        secondParentMenu="services"
        activeMenu="/api-integration"
      />
      {/* breadcrumb-area-start */}
      <SiteBreadcrumb
        breadcrumbsImg={breadcrumbsImg}
        pageTitle="API Integration"
        pageCategory="Services"
        pageName="API Integration"
      />
      {/* breadcrumb-area-end */}

      {/* About Section Start */}
      <div className="rs-about pt-120 pb-120 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 pr-40 md-pr-14 md-mb-50">
              {/* Section Title Start */}
              <SectionTitle
                sectionClass="sec-title4"
                subtitleClass="sub-text"
                subtitle="API Integration and Development"
                titleClass="title"
                title="We Help to Implement Your Ideas into Automation"
                effectClass="heading-line"
              />
              <SectionTitle
                sectionClass="sec-title4 mb-30"
                descClass="desc desc-big"
                description="Steema has the leading edge in industry expertise to deliver seamless API integration and development services. Our system integration services will ensure that your web app or website is flawlessly integrated with the standard or custom APIs. By using our proven processes, we eliminate any redundancies and potential overlaps, which might undermine your system."
                secondDescClass="desc"
                secondDescription="API integration has become an imperative system tool to maintain a competitive edge and offer customers a reliable and efficient means to use your platform. The ability of API to enable cross-platform communication makes it an invaluable online business tool for e-commerce businesses including websites and web apps."
              />
              <SectionTitle
                sectionClass="sec-title4"
                secondDescClass="desc"
                secondDescription="Today, businesses are utilizing different applications to collectively form their core business functions, as well as to manage and record systems. Therefore, it comes as no surprise when organizations are willing to integrate valuable assets into core business systems. This is a means to offer more value to customers and a larger audience."
              />
              {/* Section Title End */}
            </div>
            <div className="col-lg-6">
            <div className="rs-animation-shape">
                <div className="images">
                  <img src={aboutImg1} alt="" />
                </div>
                <div className="middle-image2">
                  <img className="dance" src={danceEffectImg} alt="" />
                </div>
              </div>
            </div>
            <div className="col-lg-12 col-xs-12 mt-30">
              <p>
                At Steema our system integration engineer has worked on several
                API and web service integration projects. So, we are your
                preferred partner when you are looking to synchronize your web
                application or website with any social media platforms including
                Twitter, Instagram, Facebook, or more complicated Google APIs.
                Our practical experience of working on different projects has
                vastly expanded our vision and capabilities. This has enabled us
                to develop a quick understanding of the project and come up with
                novel and bespoke solutions that deliver value to your business.
                With Matrix Bricks system and API integration services, you can
                be sure of witnessing your web app and website coming to life
                and your business growing leaps and bounds.
              </p>
            </div>
          </div>
        </div>
      </div>
      {/* About Section End */}

      {/* Platform Section Start */}
      <div className="rs-partner pt-60 pb-60 md-pt-80 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 pr-40 md-pr-14 md-mb-50 alignCenter">
              <h4 className="text-white">Some of the platforms we regularly use</h4>
            </div>
            <div className="col-lg-2 col-md-2 ol-sm-3 col-xs-4">
              <div className="partner-item">
                  <a href="/#">
                    <img src={brandLogo1} alt="" />
                  </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 ol-sm-3 col-xs-4">
              <div className="partner-item">
                  <a href="/#">
                    <img src={brandLogo2} alt="" />
                  </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 ol-sm-3 col-xs-4">
              <div className="partner-item">
                  <a href="/#">
                    <img src={brandLogo3} alt="" />
                  </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 ol-sm-3 col-xs-4">
              <div className="partner-item">
                  <a href="/#">
                    <img src={brandLogo4} alt="" />
                  </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 ol-sm-3 col-xs-4">
              <div className="partner-item">
                  <a href="/#">
                    <img src={brandLogo5} alt="" />
                  </a>
              </div>
            </div>
            <div className="col-lg-2 col-md-2 ol-sm-3 col-xs-4">
              <div className="partner-item">
                  <a href="/#">
                    <img src={brandLogo6} alt="" />
                  </a>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Platform Section End */}

      {/* Features Section Start */}
      <div className="rs-why-choose pt-120 pb-120 md-pt-74 md-pb-80">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 hidden-md">
              <div className="image-part">
                <img src={mainImg} alt="Main Images" />
              </div>
              <div className="animation style2">
                <div className="top-shape">
                  <img className="dance" src={animImg2} alt="Animation Two" />
                </div>
                <div className="bottom-shape">
                  <img className="dance2" src={animImg1} alt="Animation One" />
                </div>
              </div>
            </div>
            <div className="col-lg-6 pl-60 md-pl-15">
              <SectionTitle
                sectionClass="sec-title mb-40"
                subtitleClass="sub-text style4-bg"
                subtitle="Why Use API"
              />
              <div className="services-wrap mb-24">
                <img src={iconOne} alt="Choose Icon" />
                <div className="services-text">
                  <h3 className="title">
                    <a href={"/#"}>Efficiency</a>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    when access is provided to an API, the content generated can
                    be published automatically and is available for every
                    channel. It allows it to be shared plug-and-play distributed
                    more easily.
                  </p>
                </div>
              </div>
              <div className="services-wrap mb-24">
                <img src={iconTwo} alt="Choose Icon" />
                <div className="services-text">
                  <h3 className="title">
                    <a href={"/#"}>Automation</a>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    With APIs, computers rather than people can manage the work.
                    Through APIs, agencies can update workflows to make them
                    quicker and more productive.
                  </p>
                </div>
              </div>
              <div className="services-wrap">
                <img src={iconFour} alt="Choose Icon" />
                <div className="services-text">
                  <h3 className="title">
                    <a href={"/#"}>Integration</a>
                  </h3>
                  <p className="services-txt">
                    {" "}
                    APIs allow content to be embedded from any site or
                    application more easily. This guarantees more fluid
                    information delivery and an integrated user experience.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* Features Section End */}

      {/* Counter Section Start */}
      <CounterStyleThree />
      {/* Counter Section Start */}

      {/* scrolltop-start */}
      <ScrollToTop />
      {/* scrolltop-end */}

      <Footer footerClass="rs-footer" />
      <SearchModal />
    </React.Fragment>
  );
};

export default APIIntegration;
