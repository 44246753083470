import SectionTitle from "../../components/Common/SectionTitle";

// WhyChooseUs Images
import mainImg from "../../assets/img/choose/2.png";
import animImg1 from "../../assets/img/choose/dotted-1.png";
import animImg2 from "../../assets/img/choose/dotted-2.png";
import iconOne from "../../assets/img/choose/icons/1.png";
import iconTwo from "../../assets/img/choose/icons/2.png";
import iconThree from "../../assets/img/choose/icons/3.png";
import iconFour from "../../assets/img/choose/icons/4.png";

const WhyChooseUs = () => {
  return (
    <div className="rs-why-choose pt-120 pb-120 md-pt-74 md-pb-80">
      <div className="container">
        <div className="row">
          <div className="col-lg-6 hidden-md">
            <div className="image-part">
              <img src={mainImg} alt="Main Images" />
            </div>
            <div className="animation style2">
              <div className="top-shape">
                <img className="dance" src={animImg2} alt="Animation Two" />
              </div>
              <div className="bottom-shape">
                <img className="dance2" src={animImg1} alt="Animation One" />
              </div>
            </div>
          </div>
          <div className="col-lg-6 pl-60 md-pl-15">
            <SectionTitle
              sectionClass="sec-title mb-40"
              subtitleClass="sub-text style4-bg"
              subtitle="Why Choose Us"
              titleClass="title pb-20"
              title="We Create Result-Oriented Dynamic Applications"
              descClass="desc"
              description="Over 10 years working in IT services developing software applications and mobile apps for clients all over the world."
            />
            <div className="services-wrap mb-24">
              <div className="services-icon">
                <img src={iconOne} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title">
                  <a href={"/#"}>Build Integrity & Transparency</a>
                </h3>
                <p className="services-txt">
                  {" "}
                  We believe in building trust and 100% transparency with our clients.
                </p>
              </div>
            </div>
            <div className="services-wrap mb-24">
              <div className="services-icon">
                <img src={iconTwo} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title">
                  <a href={"/#"}>Deliver Perfect Solution</a>
                </h3>
                <p className="services-txt">
                  {" "}
                  We focus on building the right product that resolves users&sbquo; challenges.
                </p>
              </div>
            </div>
            <div className="services-wrap mb-24">
              <div className="services-icon">
                <img src={iconThree} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title">
                  <a href={"/#"}>Practice Agility</a>
                </h3>
                <p className="services-txt">
                  {" "}
                  We meet our clients' ever-changing needs with our agile approach.
                </p>
              </div>
            </div>
            <div className="services-wrap">
              <div className="services-icon">
                <img src={iconFour} alt="Choose Icon" />
              </div>
              <div className="services-text">
                <h3 className="title">
                  <a href={"/#"}>Drive Innovation</a>
                </h3>
                <p className="services-txt">
                  {" "}
                  We are focused on delivering client-centric innovation and value to your business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyChooseUs;
